import toast from "react-hot-toast";
import CustomButton from "../../../Button";
import { useBreakpoints } from "../../../../theme/breakpoints";
import { useListPageContext } from "../../../../contexts/listPageContext";
import { fetchData } from "../../../../utils/functions/fetchData";
import { useState } from "react";

const { Dialog, DialogTitle, DialogActions, Typography } = require("@mui/material");



const DeleteContactModal = ({ open=false,setOpen, contactDetail}) => {
    const {sm} = useBreakpoints()
    const onClose = () => setOpen && setOpen(false)
    const {handleLoadContacts} = useListPageContext()

    const [loading, setLoading] = useState(false)

    const handleDelete = async () => {
        setLoading(true)
        try{
            const {message} = await fetchData({
              endpoint: '/deleteContact',
              payload: {
                contactid: contactDetail?.id
              }
            })
      
            await handleLoadContacts({afterDeleteLoad: true})
            toast.success(message)
            onClose()
        }
        catch(err){
            toast.error(err.message)
        }
        finally{
          setLoading(false)
        }
    }


    return (
      <Dialog
      open={open}
      {...!loading && {onClose}}
      sx={{
        '& .MuiPaper-root': {
            bgcolor: 'neutral.50'
        }
      }}
      >
        <DialogTitle component='span'>
        <Typography variant="h6">
        Confirm Delete{!sm ? <>&nbsp;</> : <br />}<em>{contactDetail?.email}</em>
        </Typography>
        </DialogTitle>
        <DialogActions>
          <CustomButton
          onClick={onClose}
          sx={{
            bgcolor: 'neutral.700',
            '&: hover': {
                bgcolor: 'neutral.800'
            }
          }}
          >
            Cancel
          </CustomButton>
          <CustomButton
          autoFocus
          sx={{
            bgcolor: '#ff0000',
            '&: hover': {
                bgcolor: '#cc0000'
            }
          }}
          loading={loading}
          loadingText='deleting...'
          onClick={handleDelete}
          >
            Delete
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
};
  

export default DeleteContactModal


