import { useState } from "react";
import CustomButton from "../../Button";
import { fetchData } from "../../../utils/functions/fetchData";
import { toast } from "react-hot-toast";
import { useListPageContext } from "../../../contexts/listPageContext";

const { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } = require("@mui/material");



const DeleteList = ({ open,setOpen, listData}) => {

    const onClose = () => setOpen(false)
    const [loading, setLoading] = useState(false)

    const {setReloadLists} = useListPageContext()

    const handleDelete = async () => {
        setLoading(true)
        try{
            await fetchData({
              endpoint: '/deleteList',
              payload: {listid: listData?.id, listToken: listData?.token}
            })
            toast.success(`List ${listData?.title} deleted`)
            setReloadLists((prevValue) => !prevValue)
            
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }


    return (
      <Dialog 
      open={open} 
      onClose={!loading && onClose}
      sx={{
        '& .MuiPaper-root': {
            bgcolor: 'neutral.50'
        }
      }}
      >
        <DialogTitle >
        <Typography variant="h4">
        {`Are you sure you want to delete ${listData?.title} ?`}
        </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
            Deleting the list will dump it in recycle bin. You can restore it within 90 days.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton
          onClick={onClose}
          sx={{
            bgcolor: 'neutral.700',
            '&: hover': {
                bgcolor: 'neutral.800'
            }
          }}
          >
            Cancel
          </CustomButton>
          <CustomButton
          loadingText='Deleting list'
          loading={loading}
          onClick={handleDelete}
          autoFocus
          sx={{
            bgcolor: '#ff0000',
            '&: hover': {
                bgcolor: '#cc0000'
            }
          }}
          >
            Delete
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
};
  

export default DeleteList


