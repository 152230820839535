import { Skeleton, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { MdOutlineCreateNewFolder } from "react-icons/md"
import { toast } from "react-hot-toast"
import CustomButton from "../../../Button"
import { useBreakpoints } from "../../../../theme/breakpoints"
import { fetchData } from "../../../../utils/functions/fetchData"
import { useNavigate, useParams } from "react-router-dom"
import { useListPageContext } from "../../../../contexts/listPageContext"
import { FaEdit } from "react-icons/fa"


const textFieldStyle = {
    minWidth: '50%',
    '& .MuiInputBase-root': {
        borderRadius: '8px'
    },
    '& .Mui-focused': {
        // border: 'unset',
    },
    '& input': {
        bgcolor: 'white',
        color: '#000',
        p: '5px 15px',
        minHeight: '30px'
    },
    '& input:hover': {
        borderColor: 'primary.main'
    },
}


const ListForm = () => {
    const navigate = useNavigate()
    const {editPage} = useListPageContext()
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [editPageLoading, setEditPageLoading] = useState(false)
    const initialListData = {
        title: '',
        description: '',
        id: '',
        token: ''
    }
    const [listData, setListData] = useState(initialListData)

    if(editPage){
        var {id} = params
    }


    // For Edit Page
    useEffect( () => {
        const handleLoadListData = async () => {
            setEditPageLoading(true)
            try{
                const {data} = await fetchData({
                    endpoint: '/getListDetails',
                    payload: {listid: id},
                    noError: true
                })
                
                setListData({
                    id: data?.id,
                    title: data?.name,
                    description: data?.details,
                    token: data?.listToken
                })
            }
            catch(err){
                console.error(err.message)
            }
            finally{
                setEditPageLoading(false)
            }
        }

        editPage && handleLoadListData()
    }, [] )


    const handleCreateList = async (e) => {
        e?.preventDefault()
        if(!listData?.title){
            return toast.error(`List name cannot be empty!`)
        }
        if(!listData?.description){
            return toast.error(`Enter a description for ${listData?.title}!`)
        }
        setLoading(true)
        try{
            if(!editPage){
                const {message} = await fetchData({
                    endpoint: '/addNewList',
                    payload: {listname: listData?.title, listdetails: listData?.description}
                })
                toast.success(message)
            }
            if(editPage){
                const {message} = await fetchData({
                    endpoint: '/editList',
                    version: 3,
                    payload: {
                        "listToken": listData?.token,
                        "name": listData?.title,
                        "details": listData?.description,
                    }
                })
                toast.success(message)
            }
            navigate('/dashboard/lists')
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }





    const listFormData = [
        {
            title: 'List Name',
            value: listData?.title,
            onChange: (e) => setListData( (prevVal) =>  ({...prevVal, title: e.target.value}))
        },
        {
            title: 'List Description',
            value: listData?.description,
            multiline: true,
            onChange: (e) => setListData( (prevVal) =>  ({...prevVal, description: e.target.value}))
        },
    ]

    return (
        <form
        noValidate
        onSubmit={handleCreateList}
        >
        <Stack sx={{gap: '10px'}}>
        <Typography variant="h5" sx={{fontWeight: '600'}}>
            {!editPage ? 'Add New List' : 'Edit List'}
        </Typography>
        <Stack sx={{gap: '15px'}}>
        {
            listFormData.map( ({title, value, multiline, onChange}, k) => (
                <UserInput 
                key={k}
                title={title}
                value={value}
                multiline={multiline}
                onChange={onChange}
                editPageLoading={editPageLoading}
                />
            ) )
        }
        </Stack>
        <CustomButton
        title={!editPage ? 'Create' : 'Modify'}
        Icon={!editPage ? MdOutlineCreateNewFolder : FaEdit}
        loading={loading}
        sx={{
            mt: '20px'
        }}
        onClick={handleCreateList}
        />
        </Stack>
        </form>
    )
}

export default ListForm



const UserInput = ({title, value, multiline, onChange, editPageLoading}) => {
    const {xs} = useBreakpoints()
    

    return (
        <Stack 
        // direction={ xs ? "column" : "row"}
        sx={{justifyContent: 'space-between', gap: '10px'}}
        >
        <Typography>{title}</Typography>
        {
            !editPageLoading ?
            <TextField
            sx={textFieldStyle}
            value={value}
            onChange={onChange}
            multiline={multiline}
            rows={5}
            /> : 
            <Skeleton width='100%' height={30} />
        }
        </Stack>
    )
}