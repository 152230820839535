import { Stack, TextField, Typography } from "@mui/material"
import { useBreakpoints } from "../../../../../../theme/breakpoints"
import { useSettingsPageContext } from "../../../../../../contexts/settingsPageContext"
import CustomButton from "../../../../../Button"
import { fetchData } from "../../../../../../utils/functions/fetchData"
import { toast } from "react-hot-toast"
import { useState } from "react"
import { convertAmountToFloat } from "../../../../../../utils/functions/convertAmount"
import Logo from "../../../../../Logo"


const CardInputs = () => {
    const {sm} = useBreakpoints()
    const {
        currentTab,
        paymentDetails,
        setPaymentDetails,
        planDetails,
        setOpenOTPModal,
        setOpenAVSModal
    } = useSettingsPageContext()
    const [loading, setLoading] = useState(false)


    const makePayment = async (e) => {
        e.preventDefault()
        if(!paymentDetails.cardNumber){
            return toast.error(`Enter card number`)
        }
        if(!paymentDetails.expMonth){
            return toast.error(`Enter your card's expiry month`)
        }
        if(!paymentDetails.expYear){
            return toast.error(`Enter your card's expiry year`)
        }
        if(!paymentDetails.cvv){
            return toast.error(`CVV is required`)
        }
        if(!paymentDetails.pin){
            return toast.error(`Enter your card's pin`)
        }

        setLoading(true)
        try{
            const {data, message} = await fetchData({
                endpoint: '/chargeCard',
                payload: {
                    number: paymentDetails.cardNumber,
                    cvv: paymentDetails.cvv,
                    expiry_month: paymentDetails.expMonth,
                    expiry_year: paymentDetails.expYear,
                    amount: convertAmountToFloat(planDetails?.amount),
                    pin: paymentDetails.pin
                }
            })

            if(message !== 'Processing'){
                return toast.success(message)
            }
            const authModel = data.data.auth_model
            
            setPaymentDetails( (prevVal) => ({...prevVal, transactionRef: data?.data?.transaction_reference}))
            if(authModel === "OTP"){
                return setOpenOTPModal(true)
            }

            if(authModel === "AVS"){
                return setOpenAVSModal(true)
            }
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }
    

    return (
        <form
        noValidate
        onSubmit={makePayment}
        >
        <Stack
        sx={{
            gap: '20px'
        }}
        >
            <Stack
            sx={{width: '80px', height: '50px', alignSelf: 'center'}}
            >
            <Logo noRedirect />
            </Stack>

            <Stack
            sx={{
                gap: '20px'
            }}
            >
            <UserInput 
            title='Card Number:'
            placeholder='1234 5678 9102 3456'
            val={paymentDetails.cardNumber}
            setVal={(val) => setPaymentDetails( (prevVal) => ({...prevVal, cardNumber: val}))}
            cardNumber
            />

            <Stack
            direction='row'
            sx={{
                gap: '20px'
            }}
            >
            <UserInput 
            title='Expiry Month:'
            placeholder='MM'
            val={paymentDetails.expMonth}
            setVal={(val) => setPaymentDetails( (prevVal) => ({...prevVal, expMonth: val}))}
            maxLength={2}
            />
            <UserInput 
            title='CVV:'
            placeholder='123'
            val={paymentDetails.cvv}
            setVal={(val) => setPaymentDetails( (prevVal) => ({...prevVal, cvv: val}))}
            maxLength={3}
            />  
            </Stack>

            <Stack
            direction='row'
            sx={{
                gap: '20px'
            }}
            >
            <UserInput 
            title='Expiry Year:'
            placeholder='YY'
            val={paymentDetails.expYear}
            setVal={(val) => setPaymentDetails( (prevVal) => ({...prevVal, expYear: val}))}
            maxLength={2}
            />
            <UserInput 
            title='Pin:'
            placeholder='1234'
            val={paymentDetails.pin}
            setVal={(val) => setPaymentDetails( (prevVal) => ({...prevVal, pin: val}))}
            maxLength={4}
            />  
            </Stack>
            </Stack>

            <CustomButton 
            title={`PAY NGN ${planDetails?.amount}`}
            sx={{alignSelf: 'center'}}
            loading={loading}
            loadingText='Processing'
            // onClick={makePayment}
            type='submit'
            />
        </Stack>
        </form>
    )
}

export default CardInputs








const textFieldStyle = {
    '& .MuiInputBase-root': {
        borderRadius: '8px'
    },
    '& .Mui-focused': {
        // border: 'unset',
    },
    '& input': {
        bgcolor: 'white',
        color: '#000',
        p: '5px 15px',
        minHeight: '30px'
    },
    '& input:hover': {
        borderColor: 'primary.main'
    },
}


const UserInput = ({title, val, setVal, placeholder, maxLength, cardNumber}) => {
    const {xs} = useBreakpoints()
    
    const formatCardNumber = (input) => {
        const numericInput = input.replace(/\D/g, '');
    
        const formattedInput = numericInput.replace(/(.{4})/g, '$1 ')
    
        return formattedInput.trim()
      }

    return (
        <Stack
        sx={{gap: '3px'}}
        >
        <Typography>{title}</Typography>
        <TextField
        fullWidth
        sx={textFieldStyle}
        value={cardNumber ? formatCardNumber(val) : val}
        onChange={(e) => {
            if(e.target.value !== '' && !/^[0-9\s]+$/.test(e.target.value)){
                return
            }
            if(maxLength && (e.target.value).length > maxLength){
                return
            }
            return setVal((e.target.value).replace(/\s/g, ''))
        }}
        onPaste={(e) => {
          const pastedValue = e.clipboardData.getData('text/plain')
          const cleanedValue = pastedValue.replace(/\s/g, '')
          const formattedValue = formatCardNumber(cleanedValue)
          e.preventDefault()
          setVal(cleanedValue)
          document.execCommand('insertText', false, formattedValue)
        }}
        type='text'
        placeholder={placeholder}
        />
        </Stack>
    )
}