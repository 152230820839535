import { Stack, Typography } from '@mui/material';
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import Logo from '../../../../../Logo';
import CustomButton from '../../../../../Button';
import { useState } from 'react';
import { useSettingsPageContext } from '../../../../../../contexts/settingsPageContext';
import { convertAmountToFloat } from '../../../../../../utils/functions/convertAmount';
import { fetchFromLocalStorage } from '../../../../../../utils/functions/updateStorage';
import toast from 'react-hot-toast';
import { waitForTimeout } from '../../../../../../utils/functions/waitFunctions';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../../../../../utils/functions/fetchData';



const Flutterwave = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const {
        currentTab,
        planDetails,
    } = useSettingsPageContext()

    const userData = fetchFromLocalStorage('userData')

    const tx_ref = 'RENI_' + Math.floor(Math.random() * 1000000000000 + 1)

    const config = {
    public_key: process.env.REACT_APP_FLW_PUBLIC_KEY,
    tx_ref,
    amount: convertAmountToFloat(planDetails?.amount),
    currency: 'NGN',
    payment_options: 'card',
    customer: {
        email: userData?.email,
        phone_number: userData?.phonenumber,
        name: userData?.fullname,
    },
    customizations: {
        title: 'ReniMail Subcription',
        description: `Payment for ReniMail ${planDetails?.amount} plan`,
        logo: 'https://renimail.com/assets/images/Renimail-logo.png',
    },
    }

    const handleFlutterPayment = useFlutterwave(config)

    const handleFlutterPaymentPromise = (onClose) => {
        return new Promise( (resolve, reject) => {
            handleFlutterPayment({
                callback: (response) => {
                    closePaymentModal()
                    resolve(response)
                },
                onClose: onClose
        })
        } )
    }

    const makePayment = async (e) => {
        e.preventDefault()
        setLoading(true)
        try{
            const {status} = await handleFlutterPaymentPromise(
                () => {
                    setLoading(false)
                    toast.error('Payment cancelled')
                }
            )
            if(status === "successful"){
                const {message} =  await fetchData({
                    endpoint: '/subscribeUser-Card',
                    payload: {
                        amount: convertAmountToFloat(planDetails?.amount),
                        packageid: planDetails?.id, 
                        duration_days: planDetails?.duration,
                        tx_ref
                    }
                })
                toast.success(message || 'Payment successful')
                setLoading(false)
                return await waitForTimeout(3000, () => navigate('/dashboard/settings/subscription'))
                
            }
            return toast.error('Payment failed! Try again later')
        }
        catch(err){
            toast.error('Payment failed! Try again later')
        }
        finally{
            setLoading(false)
        }
    }
    

    return (
        <Stack
        component='form'
        noValidate
        onSubmit={makePayment}
        sx={{
            gap: '20px'
        }}
        >
            <Stack
            sx={{alignSelf: 'center'}}
            >
            <Logo noRedirect />
            </Stack>

            <Stack sx={{gap: '20px'}}>
            <Typography sx={{textAlign: 'center'}}>
            {`You're about to pay the sum of ${planDetails?.amount} using Flutterwave. Click the button below to proceed`}
            </Typography>

            <CustomButton
            title={`PAY NGN ${planDetails?.amount}`}
            sx={{alignSelf: 'center'}}
            onClick={makePayment}
            loading={loading}
            loadingText='Processing'
            type='submit'
            />
            </Stack>
        </Stack>
    )
}

export default Flutterwave