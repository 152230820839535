import { Skeleton as MUISkeleton, Stack, Typography } from "@mui/material"
import { useSettingsPageContext } from "../../../../../contexts/settingsPageContext"
import { useState } from "react"
import PaymentModal from "./PaymentModal"
import { useNavigate } from "react-router-dom"
import { renimailSupportEmail } from "../../../../../utils/functions/fetchData"



const PlanCard = ({title, amount, features, id, duration, landingPage}) => {
    const {currentTab, loading} = useSettingsPageContext()
    const [openPaymentModal, setOpenPaymentModal] = useState(false)
    const navigate = useNavigate()

    return (
        <>
        <Stack
        sx={{
            width: '300px',
            bgcolor: 'neutral.50',
            borderRadius: '15px',
            boxShadow: '0 0 20px rgb(0,0,0,.225)',
            pb: '15px',
            cursor: 'pointer',
            '& p': {
                fontWeight: 600
            },
            ml: 'auto',
            mr: 'auto'
        }}
        onClick={ () => {
            if(landingPage){
                return navigate('/login')
            }
            if(id == 4){
                window.location.href = `mailto:${renimailSupportEmail}`
                return
            }
            return id != 1 && setOpenPaymentModal(true)
        }}
        >
        <Stack
        sx={{
            p: '50px 0',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'primary.main',
            backgroundImage: 'url(/assets/images/Vector3.png)',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
            borderRadius: '15px',
            borderBottomRightRadius: '50%',
            gap: '20px',
            '& p': {
                color: 'neutral.50',
                fontWeight: 600
            }
        }}
        >
        <Stack
        sx={{textAlign: 'center'}}
        >
        <Typography>{!loading ? title : <Skeleton  />}</Typography>
        <Typography>{
            !loading ?
            `N ${currentTab==='monthly' ? amount?.month : amount?.year} / ${currentTab==='monthly' ? 'Month' : 'Year'}`
            : <Skeleton />
        }</Typography>
        </Stack>

        <Stack>
        <Typography sx={{textAlign: 'center'}}>
            {!loading ? '-Plan-' :  <Skeleton sx={{width: '50px'}} />}
        </Typography>
        </Stack>
        <Stack sx={{gap: '10px'}}>
            {
                features ? 
                features.map( (feature) => (
                    <Typography>
                        {feature}
                    </Typography>
                ) ) :
                Array.from({length: 3}).map( () => (
                    <Skeleton sx={{width: '150px'}} />
                ) )
            }
        </Stack>
        </Stack>

        <Typography sx={{alignSelf: 'flex-end', pr: '10px'}}>
        {!loading ? 'Buy Now' :  <Skeleton sx={{width: '50px'}} />}
        </Typography>


        </Stack>


        <PaymentModal 
        open={openPaymentModal}
        setOpen={setOpenPaymentModal}
        planDetails={{
            id,
            ...currentTab==='monthly' ? {amount: amount?.month} : {amount: amount?.year},
            ...currentTab==='monthly' ? {duration: duration?.month} : {duration: duration?.year}
        }}
        />
        </>
    )
}

export default PlanCard




const Skeleton = ({sx}) => {

    return (
        <MUISkeleton 
        sx={{
            width: '100px',
            bgcolor: 'neutral.400',
            ...sx
        }}
        />
    )
}