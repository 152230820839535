import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { fetchData } from "../../../utils/functions/fetchData"



const SignOut = () => {

    const handleSignOut = async () => {
        try{
            localStorage.removeItem("userData")
        
            await fetchData({
                endpoint: '/logoutUser',
                reniAuth: true
            })
        }
        catch(err){
            console.log(err.message)
        }
    }
    useEffect( () => {
        handleSignOut()
    }, [])

    return <Navigate to='/login' />
}

export default SignOut

