import { Pagination, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import CustomButton from "../../Button"
import { useBreakpoints } from "../../../theme/breakpoints"
import TableDivider from "../../TableDivider"
import removeHtmlElements from "../../../utils/functions/removeHTMLEls"
import { useState } from "react"
import { fetchData, perPage } from "../../../utils/functions/fetchData"
import { toast } from "react-hot-toast"
import { useRecycleBinContext } from "../../../contexts/recycleBinContext"




const DeletedMails = ({mails, loading}) => {
    const {sm} = useBreakpoints()

    const {
        delMailsPage, setDelMailsPage,
        totalDelMails, setTotalDelMails
    } = useRecycleBinContext()


    return (
        <Stack>
        <TableContainer
        sx={{
            minHeight: '230px',
        }}
        >
        <Table
        sx={{
            minWidth: 650,
            '& th, td': {
                p: '10px',
                fontWeight: 500,
                ...sm && {fontSize: '.9rem'}
            }
        }}
        stickyHeader
        >
            <TableHead sx={{bgcolor: 'transparent'}}>
            <TableRow>
                <TableCell>SENT TO</TableCell>
                <TableCell>SUBJECT</TableCell>
                <TableCell align="center">CONTENT</TableCell>
                <TableCell align="center"></TableCell>
            </TableRow>
            <TableDivider colSpan={4} />
            </TableHead>
            <TableBody sx={{bgcolor: 'transparent'}}>
            {   !loading && !mails.length ? 
                <TableRow
                sx={{
                    bgcolor: '#F1F1F1'
                }}
                >
                    <TableCell colSpan={4} rowSpan={3} sx={{textAlign: 'center'}}>
                    No Deleted Mails
                    </TableCell>
                </TableRow> :
                
                !loading ? (
                mails.map(({sentTo, date, subject, content, id}, k) => (
                    <Mail 
                    key={id}
                    id={id}
                    sentTo={sentTo}
                    date={date}
                    subject={subject}
                    content={content}
                    />
                ))
            ) : (
                Array.from({length: 3}).map( (_, k) => (
                    <>
                    <TableRow
                    key={k}
                    >
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell align="center"><Skeleton /></TableCell>
                    <TableCell align="center"><Skeleton /></TableCell>
                    </TableRow>
                    <TableDivider colSpan={4} />
                    </>
                ) )
            )}

            </TableBody>
        </Table>
        </TableContainer>


        {
            !loading && mails.length !== 0 &&
            <Pagination
            count={Math.ceil(totalDelMails/perPage)}
            size={ sm ? 'medium' : 'large'}
            page={delMailsPage}
            sx={{
                alignSelf: 'flex-end',
                width: 'fit-content !important'
            }}
                onChange={(e, page) => setDelMailsPage(page)}
            />
        }
        </Stack>
    )
}


export default DeletedMails



const Mail = ({id, sentTo, date, subject, content}) => {
    
    const [buttonLoading, setButtonLoading] = useState(false)

    const {setReloadDeletedMails} = useRecycleBinContext()

    const restoreMail = async (mailId) => {
        setButtonLoading(true)
        try{
            const {message} = await fetchData({
                endpoint: '/restoreDeletedMail',
                payload: {mlistid: mailId},
                noError: true
            })
                toast.success(message)
                return setReloadDeletedMails((prevValue) => !prevValue)
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setButtonLoading(false)
        }
    }


    return (
        <>
        <TableRow
        key={id}
        >
            <TableCell>
            <Typography sx={{color: 'primary.main', fontWeight: 600}}>
            {'To: ' + sentTo}
            </Typography>
            <Typography sx={{fontSize: '.85rem'}}>
                {date}
            </Typography>
            </TableCell>
            <TableCell>
            <Typography>
            {subject}
            </Typography>
            </TableCell>
            <TableCell align="center">
            <Typography>
            {content && removeHtmlElements(content).slice(0, 20) + `${removeHtmlElements(content).length >= 20 ? ' ...' : ''}`}
            </Typography>
            </TableCell>
            <TableCell align="center">
            <CustomButton 
            variant="outlined"
            squareCorners
            title='RESTORE'
            onClick={() => restoreMail(id)}
            loading={buttonLoading}
            loadingText='Restoring'
            autoFocus
            sx={{
                bgcolor: 'background.default',
                '&: hover': {
                    bgcolor: 'neutral.200'
                }
            }}
            />
            </TableCell>
        </TableRow>
        <TableDivider colSpan={4} />
        </>
    )
}