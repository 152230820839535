import axios from "axios"
import { IFetchData, IFetchDataResponse } from "./types"

export const renimailSupportEmail = 'hello@renitrust.com'
export const renimailSupportNumber = '2348037837313'


export const perPage = 10


export const getApi = ({
    reniAuth=false,
    version=2,

}) => (!reniAuth ? 
    (version===2 ? process.env.REACT_APP_API_ENDPOINT_V2 : process.env.REACT_APP_API_ENDPOINT_V3) :
    process.env.REACT_APP_RENI_AUTH_ENDPOINT)

export const getHeaders = ({reniAuth=false}) => ({
    Authorization: `Bearer ${!reniAuth ? process.env.REACT_APP_API_APPTOKEN : process.env.REACT_APP_RENI_AUTHTOKEN}`,
})

export const getUserToken = () => {
    if(localStorage.getItem('userData')){
        const userData = JSON.parse(localStorage.getItem('userData'))
        return userData?.token
    }
    return undefined
}

export const isLoggedIn = async () => {
    const {data} = await fetchData({
        endpoint: '/verifyUser',
        reniAuth: true
    })

    return data
}

export const isBVN = () => {
    if(localStorage.getItem('userData')){
        const userData = JSON.parse(localStorage.getItem('userData'))
        return userData?.isBVN
    }
    return false
}


const objectToFormData = (obj={}) => {
    const formData = new FormData()

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
        formData.append(key, obj[key])
        }
    }

    return formData
}

export const fetchData = async ({
        endpoint,
        reqMethod='post',
        payload={},
        noError=false,
        useFormData=false,
        pagination,
        reniAuth=false,
        version=2,
    }: IFetchData): Promise<IFetchDataResponse> => {
    

    const start = pagination && (perPage * (pagination.page-1)) + 1
    const end = pagination && (perPage * pagination.page) + 1

    const formData = {
        usertoken: getUserToken(),
        
        ...pagination && {
            start,
            end
        },
        ...payload
    }
    const jsonData = JSON.stringify(formData)

    const encodedData = btoa(jsonData)


    try{
        const headers = !reniAuth ? getHeaders({reniAuth: false}) : getHeaders({reniAuth: true})
        const api = `${!reniAuth ? getApi({reniAuth: false, version}) : getApi({reniAuth: true})}${endpoint}`

        const {data} = !useFormData ? 
        await axios[reqMethod](api, encodedData, {headers}) :
        await axios[reqMethod](api, objectToFormData(formData), {headers})


        if(typeof data === 'string'){
            let response: any
            try{
                response =  JSON.parse( atob(data) )
            }
            catch(err){
                throw new Error(data)
            }
            
            if(response?.success || response?.status){
                return response
            }
            console.error(response)
            if(!noError){
                throw new Error(response?.message || 'An error occurred')
            }
            else{
                return data as unknown as IFetchDataResponse
            }
        }
        else{
            if(data?.success || data?.status){
                return data
            }
            console.error(data)
            if(!noError){
                throw new Error(data?.message || 'An error occurred')
            }
            else{
                return data
            }
        }
    }
    catch(err){
        if(err.response?.data){
            if(typeof err.response?.data === 'string'){
                const response =  JSON.parse( atob(err.response?.data) )
                throw new Error(response?.message || 'An error occurred')
            }
            else{
                    throw new Error(err.response?.data?.message || 'An error occurred')
            }
        }
        throw new Error(err.message)
    }

}
