import './App.css'
import {Routes, Route} from 'react-router-dom'
import Home from './Pages/Home'
import Plan from './Pages/Plan'
import About from './Pages/About'
import Support from './Pages/Support'
import ProtectedRoutes from './Components/ProtectedRoutes'
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'rsuite/dist/rsuite.min.css'
import { ThemeProvider, responsiveFontSizes } from '@mui/material'
import { createTheme } from './theme'
import DashboardMain from './Components/Dashboard/Main'
import Compose from './Components/Dashboard/Compose'
import SettingsPage from './Components/Dashboard/Settings'
import List from './Components/Dashboard/List'
import ViewContactsPage from './Components/Dashboard/List/ViewContact'
import CreateListPage from './Components/Dashboard/List/CreateList'
import Emails from './Components/Dashboard/Emails'
import ViewEmailDetailsPage from './Components/Dashboard/Emails/ViewEmailDetails'
import ManageEmailPage from './Components/Dashboard/Emails/ManageEmail'
import PlansPage from './Components/Dashboard/Settings/Subscription/Plans'
import PushNotification from './Components/PushNotification'
import RegisterPage from './Components/Auth/Register'
import LoginPage from './Components/Auth/Login'
import ForgotPasswordPage from './Components/Auth/ForgotPassword'
import { Toaster } from 'react-hot-toast'
import SignOut from './Components/Auth/SignOut'
import ErrorPage from './Components/ErrorPage'




const App = () => {
  AOS.init()

  const theme = responsiveFontSizes(createTheme())
  return (
    <ThemeProvider theme={theme}>
      <Toaster
      position="top-center"
      toastOptions={{
        duration: 5000
      }}
      />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='pricing' element={<Plan />} />
          <Route path='about' element={<About />} />
          <Route path='support' element={<Support />} />
          <Route
          path='login' element={<LoginPage />} />
          <Route path='signout' element={<SignOut />} />
          <Route path='register' element={<RegisterPage />} />
          <Route
            path='forgot-password'
            element={<ForgotPasswordPage />}
          />
          <Route
            path='dashboard'
            element={
              <ProtectedRoutes>
                <DashboardMain />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/compose'
            element={
              <ProtectedRoutes>
                <Compose />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/compose/edit/mail/:id'
            element={
              <ProtectedRoutes>
                <Compose />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/compose/edit/draft/:id'
            element={
              <ProtectedRoutes>
                <Compose />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/emails'
            element={
              <ProtectedRoutes>
                <Emails />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/emails/viewdetails/:id'
            element={
              <ProtectedRoutes>
                <ViewEmailDetailsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/emails/manage/:id'
            element={
              <ProtectedRoutes>
                <ManageEmailPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/lists'
            element={
              <ProtectedRoutes>
                <List />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/settings'
            element={
              <ProtectedRoutes>
                <SettingsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/settings/account'
            element={
              <ProtectedRoutes>
                <SettingsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/settings/bin'
            element={
              <ProtectedRoutes>
                <SettingsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/settings/security'
            element={
              <ProtectedRoutes>
                <SettingsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/settings/subscription'
            element={
              <ProtectedRoutes>
                <SettingsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/lists/viewcontacts/:id'
            element={
              <ProtectedRoutes>
                <ViewContactsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/lists/edit/:id'
            element={
              <ProtectedRoutes>
                <CreateListPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/settings/pricing'
            element={
              <ProtectedRoutes>
                <PlansPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='dashboard/lists/create-list'
            element={
              <ProtectedRoutes>
                <CreateListPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path='*'
            element={
              <ErrorPage />
            }
          />
        </Routes>
    </ThemeProvider>
  )
}

export default App