import { CircularProgress, Divider, Fade, Popper, Stack, Typography } from "@mui/material"
import { AiFillCaretDown } from "react-icons/ai"
import { useState } from "react"
import { useRef } from "react"
import CustomButton from "../../Button"
import { useListPageContext } from "../../../contexts/listPageContext"
import { useEffect } from "react"
import DeleteList from "./DeleteList"
import EmbedList from "./EmbedList"
import AddContact from "./AddContact"
import { toast } from "react-hot-toast"
import { fetchData } from "../../../utils/functions/fetchData"
import { useNavigate } from "react-router-dom"



const TableButton = ({listData}) => {
    const [loading, setLoading] = useState(false)
    const [showPopOver, setShowPopOver] = useState(false)
    const navigate = useNavigate()
    const tableButtonRef = useRef(null)
    const popperRef = useRef(null)
    const {currentTab, setReloadLists, setReloadArchivedLists} = useListPageContext()

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openEmbedModal, setOpenEmbedModal] = useState(false)
    const [openAddContactModal, setOpenAddContactModal] = useState(false)


    const handleArchiveList = async () => {
        setLoading(true)
        try{
            const {message} = await fetchData({
                endpoint: '/markListAsAchive',
                payload: {id: listData?.id}
            })
            toast.success(message)
            setReloadLists((prevVal) => !prevVal)
            setReloadArchivedLists((prevVal) => !prevVal)

        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }

    const handleUnArchiveList = async () => {
        setLoading(true)
        try{
            const {message} = await fetchData({
                endpoint: '/unmarkListAsAchive',
                payload: {id: listData?.id}
            })
            toast.success(message)
            setReloadArchivedLists((prevVal) => !prevVal)
            setReloadLists((prevVal) => !prevVal)
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }
    


    const popOverOptions = [
        {
            title: 'Edit',
            action: () => navigate(`/dashboard/lists/edit/${listData?.id}`)
        },
        currentTab==='lists'
            ? {
                  title: 'Archive',
                  action: handleArchiveList
              }
            : {
                  title: 'UnArchive',
                  action: handleUnArchiveList
              },
        {
            title: 'Delete',
            action: () => setOpenDeleteModal(true)
        },
        {
            title: 'Embed',
            action: () => setOpenEmbedModal(true)
        },
        {
            title: 'View Contacts',
            action: () => navigate(`/dashboard/lists/viewcontacts/${listData?.id}?token=${listData?.token}`)
        }
    ]    


    const bodyHidePopup = (e) => {
        if (
            !tableButtonRef.current ||
            !popperRef.current ||
            (!tableButtonRef.current.contains(e.target) &&
            !popperRef.current.contains(e.target))
          ) {
            setShowPopOver(false)
          }
    }
    useEffect( () => {
        document.body.addEventListener('click', bodyHidePopup)
        return ( () => {
            document.body.removeEventListener('click', bodyHidePopup)
        } )
    }, [] )

    return (
        <Stack
        ref={tableButtonRef}
        direction='row'
        sx={{
            width: '95px',
            height: '40px',
            alignItems: 'center',
            bgcolor: 'primary.main',
            alignSelf: 'flex-start',
            color: 'neutral.50',
            borderRadius: '8px',
            cursor: !loading ? 'pointer' : 'not-allowed',
            ...loading && {bgcolor: 'neutral.500'},
            '& ::selection': {
                bgcolor: 'transparent'
            },
            position: 'relative'
        }}
        >
            <Stack
            sx={{
                width: '70%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onClick={ () => setOpenAddContactModal(true) }
            >
            <Typography sx={{
                color: 'neutral.50',
                alignItems: 'center',
                fontSize: '.8rem'
            }}>
                {!loading ? 'Add' : <CircularProgress sx={{color: "neutral.50"}} size={25} />}
            </Typography>
            </Stack>
            <Divider
            sx={{
                borderWidth: '1px',
                borderColor: '#000',
                height: '95%',
            }}
            />
            <Stack
            sx={{
                width: '30%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onClick={() => !loading && setShowPopOver(!showPopOver)}
            >
            <AiFillCaretDown size={15} />
            </Stack>



            {/* Pop Over */}
            <Popper
            ref={popperRef}
            open={!loading && showPopOver}
            anchorEl={tableButtonRef.current}
            transition
            sx={{
                zIndex: 1,
            }}
            onClick={(e) => e.stopPropagation()}
            >
            {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
            <Stack
            sx={{
                width: '100%',
                boxShadow: '0 0 10px rgb(0,0,0,.125)',
                borderRadius: '8px',
                bgcolor: 'background',
            }}
            >
            {
                popOverOptions.map( ({title, action}, k) => (
                    <CustomButton
                    key={k}
                    variant="normal"
                    sx={{
                        gap: '10px',
                        width: '100%',
                        p: '10px',
                        '&:hover':{
                            bgcolor: 'primary.main',
                            color: 'neutral.50'
                        },
                        '&:hover p':{
                            color: 'neutral.50'
                        }
                    }}
                    onClick={action}
                    >
                    <Typography sx={{color: 'neutral.900', fontSize: '.7rem', whiteSpace: 'nowrap', fontWeight: 500}}>
                        {title}
                    </Typography>
                    </CustomButton>
                ) )
            }
            </Stack>
            </Fade>
            )}
            </Popper>





        <DeleteList 
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        listData={listData}
        />
        <EmbedList 
        open={openEmbedModal}
        setOpen={setOpenEmbedModal}
        listData={listData}
        />
        <AddContact 
        open={openAddContactModal}
        setOpen={setOpenAddContactModal}
        listData={listData}
        />
        </Stack>
    )
}


export default TableButton