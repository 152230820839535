import { Alert, Stack, TextField, Typography } from "@mui/material"
import Layout from "../Layout"
import { useEffect, useState } from "react"
import { primary } from "../../../theme/palette"
import CustomButton from "../../Button"
import { useBreakpoints } from "../../../theme/breakpoints"
import { useNavigate } from "react-router-dom"
import { fetchData } from "../../../utils/functions/fetchData"
import { waitForTimeout } from "../../../utils/functions/waitFunctions"
import BackButton from "../../BackButton"
import Logo from "../../Logo"



const ForgotPasswordPage = () => {
    const {xs, sm, md, lg, xl} = useBreakpoints()
    const navigate = useNavigate()

    let loginLocalData = localStorage.getItem('login')
    if(loginLocalData){
        loginLocalData = JSON.parse(loginLocalData)
    }
    const [email, setEmail] = useState(loginLocalData?.email || '')


    const [loading, setLoading] = useState(false)
    const [resMessage, setResMessage] = useState({success: false, message: ''})


    const handleSubmit = async (e) => {
        e?.preventDefault()
        setLoading(true)
        try{
            const {message} = await fetchData({
                endpoint: '/forgotPassword',
                payload: {email}
            })
            setResMessage( (prevVal) => ({...prevVal, message, success: true}) )

            const dataToStore = JSON.stringify({...loginLocalData, email, pass: ''})
            localStorage.setItem('login', dataToStore)
            waitForTimeout(5000, () => navigate('/login'))
        }
        catch(err){
            console.log(err.message)
            setResMessage( (prevVal) => ({...prevVal, message: err.message, success: false}) )
        }
        finally{
            setLoading(false)
        }
    }







    const clearResponseMessage = () => {
        setResMessage( (prevVal) => ({...prevVal, message: ''}) )
    }
    

    return (
        <Layout>
        <Stack
        sx={{
            p: sm ? '50px 20px' : '50px',
            alignItems: 'center',
            bgcolor: 'neutral.50',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0,0,0,.125)',
            width: xs ? '97%' : sm ? '95%' : md ? '70%' : lg ? '50%'  : '40%',
            gap: '30px',
            height: 'fit-content'
        }}
        >
        <BackButton />
        
        <Stack
        sx={{
            textAlign: 'center',
            alignItems: 'center'
        }}
        >
        <Logo />
        </Stack>

        <Stack sx={{textAlign: 'center'}}>
        <Typography variant="h5" sx={{color: primary.main}}>
        Confirm Email
        </Typography>
        <Typography sx={{color: 'neutral.600'}}>
        Please enter your email to retrieve your password
        </Typography>
        </Stack>
        
        <form
        noValidate
        onSubmit={handleSubmit}
        style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
            width: '100%'
        }}
        >
        {
        resMessage?.message &&
        <Alert
        severity={resMessage?.success ? "success" : "error"}
        >
        <Typography>{resMessage?.message}</Typography>
        </Alert>
        }
        
        <TextField
        fullWidth
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onFocus={clearResponseMessage}
        sx={{
            '& input': {
                p: '10px 15px'
            }
        }}
        /> 

        <CustomButton 
        title='Continue'
        type='submit'
        variant="contained"
        squareCorners
        onClick={handleSubmit}
        loading={loading}
        sx={{
            alignSelf: 'center',
            textTransform: 'unset',
            p: '10px 20px'
        }}
        />
        </form>
        </Stack>
        </Layout>
    )
}

export default ForgotPasswordPage