import {initializeApp} from "firebase/app"
import {getMessaging, getToken} from "firebase/messaging"


const firebaseConfig = {
    apiKey: "AIzaSyDgs3t7c1EQOi1KQnNPhkIP0znCfPvDioc",
    authDomain: "smarrrttest.firebaseapp.com",
    projectId: "smarrrttest",
    storageBucket: "smarrrttest.appspot.com",
    messagingSenderId: "568485558941",
    appId: "1:568485558941:web:0b7fc0f0137803d58cf274"
}


// Initialize the Firebase app with the credentials
const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)


const firebaseCloudMessaging = async () => {
  try {
    // Request the push notification permission from browser
    console.log("About to ask for permission");
    const status = await Notification.requestPermission();
    if (status && status === "granted") {
      console.log("Permission granted");

      // Get new token from Firebase
      const fcm_token = await getToken(messaging, {
        vapidKey:
          "BM_LN_KIQT96GOdLuAvs8M1U1r9RtcWMvSgB7Uea3rOE7cGkCsLlYSzTW9BwESSDaM7yCzuZ8tsKlb8ygO9re9k",
      });

      // Set token in our local storage
      if (fcm_token) {
        return fcm_token;
      }

      console.log("No token returned, try again");
      return null;
    } else {
      alert("Please allow notifications access in site settings!");
      console.log("Permission not granted");
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};


export { firebaseCloudMessaging, messaging }
