import { Stack, TextField, Typography } from "@mui/material"
import { useBreakpoints } from "../../../../../../theme/breakpoints"
import { useSettingsPageContext } from "../../../../../../contexts/settingsPageContext"
import CustomButton from "../../../../../Button"
import { fetchData } from "../../../../../../utils/functions/fetchData"
import { toast } from "react-hot-toast"
import { useState } from "react"
import Logo from "../../../../../Logo"



const AVSScreen = () => {
    const {sm} = useBreakpoints()
    const {
        currentTab,
        paymentDetails,
        setPaymentDetails,
        planDetails,
        setOpenOTPModal,
        setOpenAVSModal
    } = useSettingsPageContext()
    const [loading, setLoading] = useState(false)


    const submitAVS = async (e) => {
        e.preventDefault()
        if(!paymentDetails.state || !paymentDetails.city || !paymentDetails.country || !paymentDetails.address || !paymentDetails.zipCode){
            return toast.error('All fields must be filled!')
        }
        setLoading(true)
        try{
            const {data, message} = await fetchData({
                endpoint: '/chargeCard',
                payload: {
                    "transaction_reference": [paymentDetails.transactionRef],
                    "authorization": {
                        "avs": {
                            "state": [paymentDetails.state],
                            "city": [paymentDetails.city],
                            "country": [paymentDetails.country],
                            "address": [paymentDetails.address],
                            "zip_code": [paymentDetails.zipCode]
                        }
                    }
                }
            })
            if(data.message !== 'Processing'){
                return toast.success(message)
            }
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }
    

    return (
        <form
        noValidate
        onSubmit={submitAVS}
        >
        <Stack
        sx={{
            gap: '20px'
        }}
        >
            <Stack
            sx={{alignSelf: 'center'}}
            >
            <Logo noRedirect />
            </Stack>

            <Stack
            sx={{
                gap: '5px'
            }}
            >
            <Typography sx={{textAlign: 'center'}}>
            The following details below are required to complete this transaction
            </Typography>

            <UserInput 
            title='Address:'
            placeholder=''
            val={paymentDetails.address}
            setVal={(val) => setPaymentDetails( (prevVal) => ({...prevVal, address: val}))}
            />

            <UserInput 
            title='City:'
            placeholder=''
            val={paymentDetails.city}
            setVal={(val) => setPaymentDetails( (prevVal) => ({...prevVal, city: val}))}
            />

            <UserInput 
            title='State:'
            placeholder=''
            val={paymentDetails.state}
            setVal={(val) => setPaymentDetails( (prevVal) => ({...prevVal, state: val}))}
            />

            <UserInput 
            title='Country:'
            placeholder=''
            val={paymentDetails.country}
            setVal={(val) => setPaymentDetails( (prevVal) => ({...prevVal, country: val}))}
            />

            <UserInput 
            title='Zip Code:'
            placeholder=''
            val={paymentDetails.zipCode}
            setVal={(val) => setPaymentDetails( (prevVal) => ({...prevVal, zipCode: val}))}
            />
            </Stack>

            <CustomButton 
            title={`Continue`}
            sx={{alignSelf: 'center'}}
            loading={loading}
            loadingText='Processing'
            // onClick={submitAVS}
            type='submit'
            />
        </Stack>
        </form>
    )
}

export default AVSScreen








const textFieldStyle = {
    '& .MuiInputBase-root': {
        borderRadius: '8px'
    },
    '& .Mui-focused': {
        // border: 'unset',
    },
    '& input': {
        bgcolor: 'white',
        color: '#000',
        p: '5px 15px',
        minHeight: '30px'
    },
    '& input:hover': {
        borderColor: 'primary.main'
    },
}


const UserInput = ({title, val, setVal, placeholder, maxLength}) => {
    const {xs} = useBreakpoints()
    

    return (
        <Stack
        sx={{gap: '3px'}}
        >
        <Typography>{title}</Typography>
        <TextField
        fullWidth
        sx={textFieldStyle}
        value={val}
        onChange={(e) => {
            return setVal(e.target.value)
        }}
        type='text'
        placeholder={placeholder}
        />
        </Stack>
    )
}