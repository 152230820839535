import { Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useEffect } from "react"
import { toast } from "react-hot-toast"
import { useBreakpoints } from "../../../../../theme/breakpoints"
import { fetchData } from "../../../../../utils/functions/fetchData"
import BackButton from "../../../../BackButton"
import Layout from "../../../../Layouts/DashboardLayout"
import CustomButton from "../../../../Button"
import PlanCard from "./PlanCard"
import SettingsPageContext from "../../../../../contexts/settingsPageContext"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import SwiperButton from "../../../../SwiperButton"
import { useLayoutContext } from "../../../../../contexts/layoutContext"



export const Plans = ({landingPage=false}) => {
    const {xs, sm, md, mdOnly, lg, xl} = useBreakpoints()
    const [loading, setLoading] = useState(false)
    const [currentTab, setCurrentTab] = useState('monthly')
    const [plans, setPlans] = useState([])
    const layoutContext = useLayoutContext()




    const handleLoad = async () => {
        setLoading(true)
        try{
            const {data} = await fetchData({
                endpoint: '/getPackages',
                noError: true
            })
            setPlans( data.slice(landingPage ? 0 : 1).map( ({name, amount_th, amount_year_th, features, id, duration_days, duration_days_year}) => ({
                title: name,
                amount: {
                    month: amount_th,
                    year: amount_year_th
                },
                duration: {
                    month: duration_days,
                    year: duration_days_year
                },
                features: features.map( ({details}) =>  details),
                id
            }) ) )
        } 
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }

    useEffect( () => {
        handleLoad()
    }, [])


    const tabStyle = {
        border: 'none !important',

    }

    return (
        <SettingsPageContext.Provider
        value={{
            loading,
            currentTab,
        }}
        >
        <Stack
        sx={{
            p: sm ? '10px' : md ? '20px' : '50px',
            gap: '30px'
        }}
        >
        <Stack
        direction='row'
        sx={{justifyContent: 'space-between'}}
        >
            <Typography variant="h4">
                Pricing
            </Typography>
            {
            !landingPage &&
            <Stack>
            <BackButton />
            </Stack>
            }
        </Stack>

        <Stack
        direction='row'
        sx={{
            alignSelf: 'center',
            border: '2px solid',
            borderColor: 'primary.main',
            borderRadius: '25px'
        }}
        >
        <CustomButton 
        title='Monthly'
        variant={currentTab==='monthly' ? 'contained' : 'outlined'}
        sx={tabStyle}
        onClick={ () => setCurrentTab('monthly') }
        />
        <CustomButton 
        title='Yearly'
        variant={currentTab==='yearly' ? 'contained' : 'outlined'}
        sx={tabStyle}
        onClick={ () => setCurrentTab('yearly') }
        />
        </Stack>

        <Swiper
        spaceBetween={'10px'}
        slidesPerView={xs ? 0.9 : sm ? 1.3 : md ? 2  : lg ? (!layoutContext?.miniSideBar ? 2.05 : 2.6) : xl ? (!layoutContext?.miniSideBar ? 2.5 : 3.5) : (!layoutContext?.miniSideBar ? 3.2 : 4)}
        initialSlide={1.5}
        style={{
            width: '99%',
            zIndex: 2,
            marginTop: 'auto',
            paddingTop: '50px',
            paddingBottom: '50px',
            position: 'relative'
        }}
        >
        {
            !loading ?
            plans.map( ({title, amount, features, id, duration}, k) => (
                <SwiperSlide
                key={currentTab==='monthly' ? amount?.month : amount?.year}
                >
                <PlanCard
                title={title}
                amount={amount}
                duration={duration}
                features={features}
                id={id}
                landingPage={landingPage}
                />
                </SwiperSlide>
            ) ) :
            Array.from({length: 3}).map( (_, k) => (
                <SwiperSlide key={k}>
                <PlanCard />
                </SwiperSlide>
            ) )
        }

        <SwiperButton
        prev
        sx={{
            position: 'absolute',
            top: '45%',
            left: 0,
            zIndex: 3
        }}
        />
        <SwiperButton
        next
        sx={{
            position: 'absolute',
            top: '45%',
            right: 0,
            zIndex: 3
        }}
        />
        </Swiper>

        </Stack>
        </SettingsPageContext.Provider>
    )
}


const PlansPage = () => {

    return (
        <Layout>
        <Plans />
        </Layout>
    )
}

export default PlansPage