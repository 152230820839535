import { Stack, TextField, Typography } from "@mui/material"
import { useBreakpoints } from "../../../../../../theme/breakpoints"
import { useSettingsPageContext } from "../../../../../../contexts/settingsPageContext"
import CustomButton from "../../../../../Button"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { fetchData } from "../../../../../../utils/functions/fetchData"
import { toast } from "react-hot-toast"
import { useLayoutContext } from "../../../../../../contexts/layoutContext"
import { convertAmountToFloat } from "../../../../../../utils/functions/convertAmount"
import Logo from "../../../../../Logo"



const ReniTrust = () => {
    const {sm} = useBreakpoints()
    const {
        currentTab,
        paymentDetails,
        setPaymentDetails,
        planDetails,
    } = useSettingsPageContext()
    const {
        userData,
        userBalance,
        reniUserDetails,
        reniUserDetailsLoading
    } = useLayoutContext()

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    


    const makePayment = async (e) => {
        e.preventDefault()
        setLoading(true)
        try{
            const {data, message} = await fetchData({
                endpoint: '/chargeUserAccount',
                payload: {
                    packageid: planDetails?.id,
                    duration_days: currentTab==='monthly' ? 30 : 365,
                    amount: convertAmountToFloat(planDetails?.amount),
                    narration: 'RENIMAIL SUBSCRIPTION',
                }
            })
            
            console.log(data)
            return toast.success(message)
            
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }

    
    
    const reniUserDetailsOps = [
        {
            title: 'Account Name',
            value: reniUserDetails?.name
        },
        {
            title: 'Account Number',
            value: reniUserDetails?.nuban
        },
        {
            title: 'Bank Name',
            value: reniUserDetails?.bankname
        },
        {
            title: 'Available Balance',
            value: userBalance?.value ? `NGN ${userBalance?.value}` : 'Unavailable at the moment'
        }
    ]

    return (
        <Stack
        component='form'
        noValidate
        onSubmit={makePayment}
        sx={{
            gap: '20px'
        }}
        >
            <Stack
            sx={{alignSelf: 'center'}}
            >
            <Logo noRedirect />
            </Stack>

            {
            !reniUserDetailsLoading && reniUserDetails ?
            <>
            <Typography sx={{ textAlign: 'center' }}>
            <span>The account below is registered to&nbsp;</span>
            <span style={{ fontStyle: 'italic', fontWeight: 600 }}>
                {userData?.fullname}
            </span>
            <span>&nbsp;and can be funded by transfers from any other bank.<br/>It can also be used to make payments on ReniMail.</span>
            </Typography>

            <Stack
            sx={{
                gap: '5px'
            }}
            >
            {
                reniUserDetailsOps.map( ({title, value}, k) => (
                    <UserInput
                    key={k}
                    title={title}
                    val={value}
                    />
                ) )
            }

            <Typography sx={{textAlign: 'center'}}>
            Please Confirm The Details Above Before Proceeding To Make Payment.
            </Typography>

            </Stack>

            <CustomButton 
            title={`PAY NGN ${planDetails?.amount}`}
            sx={{alignSelf: 'center'}}
            onClick={makePayment}
            loading={loading}
            loadingText='Processing'
            type='submit'
            />
            </> :

            <Stack sx={{gap: '20px'}}>
            <Typography sx={{textAlign: 'center'}}>
            You do not have a ReniTrust Account. Kindly update your BVN to get an account number
            </Typography>

            <CustomButton 
            title='Update BVN'
            sx={{alignSelf: 'center'}}
            onClick={ () => navigate('/settings/security') }
            />
            </Stack>
            }
        </Stack>
    )
}

export default ReniTrust








const textFieldStyle = {
    '& .MuiInputBase-root': {
        borderRadius: '8px'
    },
    '& .Mui-focused': {
        // border: 'unset',
    },
    '& input': {
        bgcolor: 'white',
        color: '#000',
        p: '5px 15px',
        minHeight: '30px'
    },
    '& input:hover': {
        borderColor: 'primary.main'
    },
}


const UserInput = ({title, val}) => {
    const {xs} = useBreakpoints()
    

    return (
        <Stack
        sx={{gap: '3px'}}
        >
        <Typography>{title}</Typography>
        <TextField
        fullWidth
        sx={textFieldStyle}
        value={val}
        type='text'
        disabled
        />
        </Stack>
    )
}