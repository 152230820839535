import { Alert, Checkbox, IconButton, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import CustomButton from "../../Button"
import VCodeTextField from "../../VCodeTextField"
import { useBreakpoints } from "../../../theme/breakpoints"
import { useRegisterPageContext } from "../../../contexts/registerPageContext"
import { useState } from "react"
import { fetchData } from "../../../utils/functions/fetchData"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { waitForTimeout } from "../../../utils/functions/waitFunctions"



const RegisterForm = () => {
    const {xs} = useBreakpoints()
    const {registerDetails, 
        setRegisterDetails, 
        currentScreen, 
        setCurrentScreen,
        nullRegisterDetails
    } = useRegisterPageContext()
    const [loading, setLoading] = useState(false)
    const [resMessage, setResMessage] = useState({success: false, message: ''})

    const navigate = useNavigate()


    const handleSubmit = async (e) => {
        e?.preventDefault()
        setLoading(true)
        try{
            const {message} = await fetchData({
                endpoint: '/completeRegister',
                payload: {
                    "fname": registerDetails?.fName,
                    "lname": registerDetails?.lName,
                    "username": registerDetails?.userName,
                    "gender": registerDetails?.gender,
                    "mail": registerDetails?.email,
                    "pword": registerDetails?.pass,
                    "cpword": registerDetails?.confirmPass,
                    "phone": registerDetails?.tel
                },
                reniAuth: true
            })
            setResMessage( (prevVal) => ({...prevVal, message, success: true}) )

            await waitForTimeout(1500)
            setCurrentScreen('default')
            setRegisterDetails(nullRegisterDetails)
            await waitForTimeout(100)
            navigate('/login')
        }
        catch(err){
            console.log(err.message)
            setResMessage( (prevVal) => ({...prevVal, message: err.message, success: false}) )
        }
        finally{
            setLoading(false)
        }
    }

    const clearResponseMessage = () => {
        setResMessage( (prevVal) => ({...prevVal, message: ''}) )
    }

    const handleContinue = () => {
        setCurrentScreen('complete-register')
    }


    const handleKeyDown = (e) => {
        if(e.nativeEvent.code === 'Enter' || e.nativeEvent.key === 'Enter'){
            if(currentScreen==='register'){
                return setCurrentScreen('complete-register')
            }
            if(currentScreen==='complete-register'){
                handleSubmit()
            }
            e.target.blur()
        }
    }


    const form = [
        {
            title: 'First Name',
            value: registerDetails?.fName,
            onChange: (e) => setRegisterDetails( (prevVal) => ({...prevVal, fName: e.target.value}) )
        },
        {
            title: 'Last Name',
            value: registerDetails?.lName,
            onChange: (e) => setRegisterDetails( (prevVal) => ({...prevVal, lName: e.target.value}) )
        },
        {
            title: 'Email',
            value: registerDetails?.email,
            readOnly: true
        },
        {
            title: 'Phone Number',
            value: registerDetails?.tel,
            onChange: (e) => setRegisterDetails( (prevVal) => ({...prevVal, tel: e.target.value}) )
        },
        {
            title: 'Gender',
            value: registerDetails?.gender,
            onChange: (e) => setRegisterDetails( (prevVal) => ({...prevVal, gender: e.target.value}) ),
            options: ['Male', 'Female']
        },
        {
            title: 'Username',
            value: registerDetails?.userName,
            onChange: (e) => setRegisterDetails( (prevVal) => ({...prevVal, userName: e.target.value}) )
        },
        {
            title: 'Password',
            value: registerDetails?.pass,
            onChange: (e) => setRegisterDetails( (prevVal) => ({...prevVal, pass: e.target.value}) ),
            password: true,
        },
        {
            title: 'Confirm Password',
            value: registerDetails?.confirmPass,
            onChange: (e) => setRegisterDetails( (prevVal) => ({...prevVal, confirmPass: e.target.value}) ),
            password: true,
        }
    ]

    return (
        <Stack
        sx={{
            gap: '20px',
            width: '100%'
        }}
        >
        {
        resMessage?.message &&
        <Alert
        severity={resMessage?.success ? "success" : "error"}
        >
        <Typography>{resMessage?.message}</Typography>
        </Alert>
        }
        <form
        onSubmit={currentScreen==='register' ? handleContinue : handleSubmit}
        style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        }}
        >
        {
        form.slice(currentScreen==='register' ? 0 : 4, currentScreen==='register' ? 4 : form.length)
        .map( ({title, value, onChange, readOnly, options, password}, k) => (
            <Stack
            key={title}
            sx={{width: '100%'}}>
            <Typography>
            {title}
            </Typography>
            <Input 
            value={value}
            readOnly={readOnly}
            password={password}
            options={options}
            onChange={onChange}
            clearResponseMessage={clearResponseMessage}
            onKeyDown={handleKeyDown}
            />
            </Stack>
        ) )
        }

        {
        currentScreen==='complete-register' &&
        <Stack 
        direction='row'
        sx={{gap: '5px', alignItems: 'center'}}
        >
        <Checkbox
        checked={registerDetails?.agree}
        onChange={ (_, checked) => setRegisterDetails( (prevVal) => ({...prevVal, agree: checked}) ) }
        />
        <Typography>
        I Have Read And Agree To All The Terms&Conditions And Privacy Policy
        </Typography>
        </Stack>
        }

        <CustomButton 
        title={currentScreen==='register' ? 'Continue' : 'Join Now'}
        variant="contained"
        squareCorners
        onClick={currentScreen==='register' ? handleContinue : handleSubmit}
        loading={loading}
        sx={{
            alignSelf: 'center',
            textTransform: 'unset',
            p: '10px 20px'
        }}
        />
        </form>
        </Stack>
    )
}

export default RegisterForm




const Input = ({value, password, onChange, onKeyDown, readOnly, clearResponseMessage, options}) => {

    const [showPassword, setShowPassword] = useState(false)

    return (
        <>
        {
        !options ? 
        <TextField 
        fullWidth
        type={ password ? (showPassword ? 'text' : 'password') : 'text' }
        value={value}
        onChange={onChange}
        onFocus={clearResponseMessage}
        onKeyDown={onKeyDown}
        sx={{
            '& input': {
                p: '10px 15px',
                ...readOnly && {
                    color: 'neutral.500'
                }
            },
            '& .MuiInputBase-root': {
                ...readOnly && {
                    bgcolor: 'neutral.200',
                }
            }
        }}
        InputProps={{
        endAdornment: (
            <>
            {
                password &&
                <InputAdornment position="end">
                    <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => setShowPassword(!showPassword)}
                    >
                        {
                            showPassword ?
                            <FaEyeSlash size={15} /> :
                            <FaEye size={15} />
                        }
                    </IconButton>
                </InputAdornment>
            }
            </>
        ),
    }}
        /> :
        <Select
        // variant="standard"
        value={value}
        onFocus={clearResponseMessage}
        fullWidth
        onChange={ onChange }
        sx={{
            '& .MuiSelect-select': {
                p: '10px 15px'
            }
        }}
        >
        <MenuItem value={'default'}>Select</MenuItem>
        {
        options.map( (option) => (
            <MenuItem 
            value={option}
            key={option}
            >
            {option}
            </MenuItem>
        ) )
        }
        </Select>
        }
        </>
    )
}