import { IconButton, Pagination, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import {useLocation, useNavigate, useParams, useHistory } from "react-router-dom"
import { fetchData, perPage } from "../../../../utils/functions/fetchData"
import { useBreakpoints } from "../../../../theme/breakpoints"
import { neutral } from "../../../../theme/palette"
import TableDivider from "../../../TableDivider"
import { FaClipboardList } from "react-icons/fa"
import { BsPersonFillAdd } from "react-icons/bs"
import EmbedList from "../EmbedList"
import AddContact from "../AddContact"
import ListPageContext, { useListPageContext } from "../../../../contexts/listPageContext"
import InfoView from "../../InfoView"
import {ImEmbed2} from 'react-icons/im'
import ContactRow from "./ContactRow"
import useUrlQuery from "../../../../hooks/useUrlQuery"



const ContactTable = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const {sm, md} = useBreakpoints()
    const [tableData, setTableData] = useState([])
    const initialListData = {
        title: null,
        id: null,
        embed: null
    }
    const [listData, setListData] = useState(initialListData)
    const {reloadContacts, ...restContextValues} = useListPageContext()
    const [totalContacts, setTotalContacts] = useState(0)

    const pageQuery = useUrlQuery().get('page')
    const [contactsPage, setContactsPage] = useState(parseInt(pageQuery) || 1)


    const [loading, setLoading] = useState(false)

    const {id} = useParams()
    const listToken = useUrlQuery().get('token')


    const [openEmbedModal, setOpenEmbedModal] = useState(false)
    const [openAddContactModal, setOpenAddContactModal] = useState(false)


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        params.set('page', contactsPage)
    
        navigate({
          pathname: location.pathname,
          search: params.toString(),
        });
    }, [contactsPage])


    useEffect( () => {
        const handleLoadListData = async () => {
            try{
                const {data} = await fetchData({
                    endpoint: '/getListDetails',
                    payload: {listid: id},
                })
                
                setListData({
                    id: data?.id,
                    title: data?.name,
                    embed: data?.embed,
                    token: data?.listToken,
                })
            }
            catch(err){
                console.error(err.message)
                navigate('/dashboard/lists')
            }
        }

        handleLoadListData()
    }, [] )


    const handleLoadContacts = async ({afterDeleteLoad=false}={}) => {
        !afterDeleteLoad && setLoading(true)
        try{
            const page = (contactsPage!==1 && afterDeleteLoad && tableData.length===1) ? contactsPage - 1 : contactsPage

            // So that it won't run this function twice since 'contactsPage' is in a useEffect dependency array
            if(page !== contactsPage){
                return setContactsPage(page)
            }

            const response = await fetchData({
                endpoint: '/getContacts',
                version: 3,
                payload: {listToken},
                pagination: {
                    page
                }
            })

            const newContacts = response && response.data ? response.data.map( ({fname, lname, mail, status, id}) => ({
                fname,
                lname,
                email : mail,
                status,
                id
                }) ) : []

            
            setTableData(newContacts)

            setTotalContacts(response?.total)
        }
        catch(err){
            console.error(err.message)
            navigate('/dashboard/lists')
        }
        finally{
            setLoading(false)
        }
    }
    useEffect( () => {
        handleLoadContacts()
    }, [reloadContacts, contactsPage])

    const setContact = (newContact, id) => {

        return setTableData(prevTableData => {
            return prevTableData.map( (contact) => {
                if (typeof newContact === 'function'){
                    if(contact?.id === id){
                        return newContact(contact)
                    }
                    return contact
                }

                if(contact?.id === newContact?.id){

                    return newContact
                }
                return contact
            } )
        })
    }

    return (
        <ListPageContext.Provider
        value={{
            reloadContacts, ...restContextValues,
            setContact,
            handleLoadContacts
        }}
        >
        <Stack
        sx={{
            width: '100%',
            bgcolor: 'neutral.50',
            boxShadow: '0 0 10px rgb(0,0,0,.125)',
            border: '1px solid',
            borderColor: 'neutral.600',
            borderRadius: '20px',
            p: '15px 10px',
            gap: '20px'
        }}
        >
        <Stack 
        direction={md ? 'column' : 'row'} 
        sx={{justifyContent: 'space-between', gap: '20px'}}>
        <Stack direction='row' sx={{alignItems: 'center', gap: '10px'}}>
        <Stack
        sx={{
            width: '40px', height: '40px',
            borderRadius: '50%',
            alignItems: 'center', justifyContent: 'center',
            bgcolor: '#C30B90',
        }}
        >
            <FaClipboardList size={25}  color={neutral[50]}/>
        </Stack>
        <Typography sx={{fontWeight: 500}}>
            {!loading ? `${listData?.title}  (${totalContacts} Contacts)` : <Skeleton width={30} height={20}  />}
        </Typography>
        </Stack>

        <Stack 
        direction='row' 
        sx={{gap: '10px', alignItems: 'center', justifyContent: 'flex-end'}}
        >
        <InfoView
        tooltip
        tooltipInfo='Embed HTML'
        disabled={loading}
        >
        <IconButton
        onClick={() => setOpenEmbedModal(true)}
        disabled={loading}
        sx={{bgcolor: 'primary.main', color: 'neutral.50', '&:hover': {bgcolor: 'primary.main'}}}
        >
        <ImEmbed2 />
        </IconButton>
        </InfoView>

        <InfoView
        tooltip
        tooltipInfo='Add Contact'
        disabled={loading}
        >
        <IconButton
        onClick={() => setOpenAddContactModal(true)}
        disabled={loading}
        sx={{bgcolor: 'primary.main', color: 'neutral.50', '&:hover': {bgcolor: 'primary.main'}}}
        >
        <BsPersonFillAdd />
        </IconButton>
        </InfoView>
        </Stack>
        </Stack>

        <TableContainer
        sx={{
            minHeight: '230px',
        }}
        >

        <Table
        sx={{
            minWidth: 650,
            '& th, td': {
                p: sm ? '1px 10px' : '3px 5px',
                fontWeight: 500,
                ...md && {
                    '&, & p': {
                        fontSize: '.9rem'
                    },
                },
                ...sm && {
                    '&, & p': {
                        fontSize: '.8rem'
                    },
                }
            }
        }}
        >
            <TableHead sx={{bgcolor: 'transparent'}}>
            <TableDivider colSpan={6} />
            <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Edit</TableCell>
                <TableCell align="center">Delete</TableCell>
            </TableRow>
            <TableDivider colSpan={6} />
            </TableHead>
            <TableBody>
            {   !loading && !tableData.length ? 
                <TableRow
                sx={{
                    bgcolor: '#F1F1F1'
                }}
                >
                    <TableCell colSpan={6} rowSpan={3} sx={{textAlign: 'center'}}>
                    This List has no contact
                    </TableCell>
                </TableRow> :
                
                !loading ? (
                tableData.map(({ fname, lname, email, status, id}, k) => (
                    <ContactRow
                    key={id}
                    fname={fname}
                    lname={lname}
                    email={email}
                    status={status}
                    id={id}
                    k={k}
                    />
                ))
            ) : (
                Array.from({length: perPage}).map( (_, k) => (
                    <TableRow
                        key={k}
                        sx={{
                            bgcolor: k % 2 ? 'neutral.50' : '#F1F1F1'
                        }}
                    >
                    {
                        Array.from({length: 6}).map( (_, k) => (
                            <TableCell key={k}><Skeleton /></TableCell>
                        ) )
                    }
                </TableRow>
                ) )
            )}

            </TableBody>
        </Table>        
        </TableContainer>

        {
            !loading && tableData.length !== 0 &&
            <Pagination
            count={Math.ceil(totalContacts/perPage)}
            size={ sm ? 'medium' : 'large'}
            page={contactsPage}
            sx={{
                alignSelf: 'flex-end',
                width: 'fit-content !important'
            }}
                onChange={(e, page) => setContactsPage(page)}
            />
        }





        <EmbedList
        open={openEmbedModal}
        setOpen={setOpenEmbedModal}
        listData={listData}
        />
        <AddContact
        open={openAddContactModal}
        setOpen={setOpenAddContactModal}
        listData={listData}
        />
        </Stack>
        </ListPageContext.Provider>
    )
}

export default ContactTable