import { Pagination, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import CustomButton from "../../Button"
import { useBreakpoints } from "../../../theme/breakpoints"
import TableDivider from "../../TableDivider"
import { useState } from "react"
import { fetchData, perPage } from "../../../utils/functions/fetchData"
import { toast } from "react-hot-toast"
import { useRecycleBinContext } from "../../../contexts/recycleBinContext"




const DeletedLists = ({lists, loading}) => {
    const {sm} = useBreakpoints()

    const {
        delListsPage, setDelListsPage,
        totalDelLists, setTotalDelLists,
    } = useRecycleBinContext()

    return (
        <Stack>
        <TableContainer
        sx={{
            minHeight: '230px',
        }}
        >
        <Table
        sx={{
            minWidth: 650,
            '& th, td': {
                p: '10px',
                fontWeight: 500,
                ...sm && {fontSize: '.9rem'}
            }
        }}
        stickyHeader
        >
            <TableHead sx={{bgcolor: 'transparent'}}>
            <TableRow>
                <TableCell>TITLE</TableCell>
                <TableCell>DESCRIPTION</TableCell>
                <TableCell align="center">TOTAL CONTACTS</TableCell>
                <TableCell align="center"></TableCell>
            </TableRow>
            <TableDivider colSpan={4} />
            </TableHead>
            <TableBody sx={{bgcolor: 'transparent'}}>
            {   !loading && !lists.length ? 
                <TableRow
                sx={{
                    bgcolor: '#F1F1F1'
                }}
                >
                    <TableCell colSpan={4} rowSpan={3} sx={{textAlign: 'center'}}>
                    No Deleted Lists
                    </TableCell>
                </TableRow> :
                
                !loading ? (
                lists.map(({title, date, description, totalContacts, id}, k) => (
                    <List 
                    key={id}
                    title={title}
                    description={description}
                    totalContacts={totalContacts}
                    id={id}
                    />
                ))
            ) : (
                Array.from({length: 3}).map( (_, k) => (
                    <>
                    <TableRow
                    key={k}
                    >
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell align="center"><Skeleton /></TableCell>
                    <TableCell align="center"><Skeleton /></TableCell>
                    </TableRow>
                    <TableDivider colSpan={4} />
                    </>
                ) )
            )}

            </TableBody>
        </Table>
        </TableContainer>


        {
            !loading && lists.length !== 0 &&
            <Pagination
            count={Math.ceil(totalDelLists/perPage)}
            size={ sm ? 'medium' : 'large'}
            page={delListsPage}
            sx={{
                alignSelf: 'flex-end',
                width: 'fit-content !important'
            }}
                onChange={(e, page) => setDelListsPage(page)}
            />
        }
        </Stack>
    )
}


export default DeletedLists




const List = ({id, title, description, totalContacts}) => {
    const [buttonLoading, setButtonLoading] = useState()

    const {setReloadDeletedLists} = useRecycleBinContext()

    const restoreList = async (listId) => {
        setButtonLoading(true)
        try{
            const {message} = await fetchData({
                endpoint: '/restoreDeletedList', 
                payload: {listid: listId},
                noError: true
            })
            toast.success(message)
            return setReloadDeletedLists((prevValue) => !prevValue)
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message || 'An error occurred, try again later')
        }
        finally{
            setButtonLoading(false)
        }
    }



    return (
        <>
        <TableRow>
            <TableCell>
            <Typography sx={{color: 'primary.main', fontWeight: 600}}>
            {title}
            </Typography>
            </TableCell>
            <TableCell>
            <Typography>
            {description}
            </Typography>
            </TableCell>
            <TableCell align="center">
            <Typography>
            {totalContacts + ' Contacts'}
            </Typography>
            </TableCell>
            <TableCell align="center">
            <CustomButton 
            variant="outlined"
            squareCorners
            title='RESTORE'
            onClick={() => !buttonLoading && restoreList(id)}
            loading={buttonLoading}
            disabled={buttonLoading}
            fullWidth
            />
            </TableCell>
        </TableRow>
        <TableDivider colSpan={4} />
        </>
    )
}