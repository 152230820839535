import { Slide, Stack } from "@mui/material"
import { useEffect } from "react"
import { RiDashboardLine, RiLoginBoxFill } from "react-icons/ri"
import { useState } from "react"
import SignOutModal from "../Auth/SignOut/SignOutModal"
import { useAuthPageContext } from "../../contexts/authPageContext"
import { useBreakpoints } from "../../theme/breakpoints"
import { neutral, primary } from "../../theme/palette"
import MediaMenuOption from "../Dashboard/TopNav/MediaMenuOption"
import { nav } from "./nav"
import { FaUserPlus } from "react-icons/fa"
import { LiaSignOutAltSolid } from "react-icons/lia"



const MediaMenu = () => {
    const {
        openMediaMenu,
        setOpenMediaMenu,
    } = useAuthPageContext()
    const {md} = useBreakpoints()

    const userData = JSON.parse(localStorage.getItem('userData'))

    useEffect( () => {
        !md && setOpenMediaMenu(false)
    }, [md] )



    const [openSignOutModal, setOpenSignOutModal] = useState(false)





    return (
        <>
        <Slide
        direction="left"
        mountOnEnter 
        unmountOnExit
        in={openMediaMenu}
        >
        <Stack
        className="media-menu-pop-over"
        sx={{
            bgcolor: 'primary.main',
            zIndex: 15,
            width: '170px',
            height: '100vh',
            p: '10px',
            boxShadow: '0 0 10px rgb(0,0,0,.125)',
            pointerEvents: openMediaMenu ? 'auto' : 'none',
            gap: '100px',
            position: 'fixed',
            right: 0,
            top: 83
        }}
        onClick={(e) => e.stopPropagation()}
        >
        <Stack
        sx={{gap: '20px'}}
        >
        {
            nav.map( ({title, Icon, route, id}, k) => (
                <MediaMenuOption
                key={k}
                title={title}
                Icon={Icon}
                route={route}
                id={id}
                />
            ) )
        }
        </Stack>
        <Stack
        sx={{gap: '20px'}}
        >

        {
        !userData ?
        <MediaMenuOption
        title={'Login'}
        Icon={RiLoginBoxFill}
        route={'/login'}
        id={'login'}
        /> :
        <MediaMenuOption
        title={'Dashboard'}
        Icon={RiDashboardLine}
        route={'/dashboard'}
        id={'dashboard'}
        />
        }

        {
        !userData ?
        <MediaMenuOption
        title={'Sign Up For Free'}
        Icon={FaUserPlus}
        route={'/register'}
        id={'register'}
        /> :
        <MediaMenuOption
        title={'Sign Out'}
        Icon={LiaSignOutAltSolid}
        action={() => setOpenSignOutModal(true)}
        />
        }

        </Stack>
        </Stack>
        </Slide>


        <SignOutModal
        open={openSignOutModal}
        setOpen={setOpenSignOutModal}
        />
        </>
    )
}

export default MediaMenu