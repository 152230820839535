import {Stack, Typography } from "@mui/material"
import { useBreakpoints } from "../../../theme/breakpoints"
import { useState } from "react"
import Search from "../../Search"
import DeletedMails from "./DeletedMails"
import { useEffect } from "react"
import DeletedLists from "./DeletedLists"
import { fetchData } from "../../../utils/functions/fetchData"
import { toast } from "react-hot-toast"
import RecycleBinContext from "../../../contexts/recycleBinContext"



const Bin = () => {
    const [currentTab, setCurrentTab] = useState('mails')
    const {sm, md} = useBreakpoints()
    const [deletedMailsLoading, setDeletedMailsLoading] = useState(false)
    const [deletedListsLoading, setDeletedListsLoading] = useState(false)
    const [deletedMails, setDeletedMails] = useState([])
    const [deletedLists, setDeletedLists] = useState([])

    const [reloadDeletedMails, setReloadDeletedMails] = useState(false)
    const [reloadDeletedLists, setReloadDeletedLists] = useState(false)

    const [delListsPage, setDelListsPage] = useState(1)
    const [delMailsPage, setDelMailsPage] = useState(1)

    const [totalDelLists, setTotalDelLists] = useState(0)
    const [totalDelMails, setTotalDelMails] = useState(0)




    const fetchDeletedMails = async () => {
        setDeletedMailsLoading(true)
        try{
            const response = await fetchData({
                endpoint: '/getDeletedMails',
                noError: true,
                pagination: {
                    page: delMailsPage
                }
            })

            response && response.data ?
            setDeletedMails( response.data.map( ({listData, real_date, subject, message, id}) => ({
                sentTo: listData?.name,
                date: real_date,
                subject,
                content: message,
                id
            }) ) ) : setDeletedMails([])

            setTotalDelMails(response?.total)
        }
        catch(err){
            console.log(err.message)
            // toast.error(err.message)
        }
        finally{
            setDeletedMailsLoading(false)
        }
    }

    const fetchDeletedLists = async () => {
        setDeletedListsLoading(true)
        try{
            const response = await fetchData({
                endpoint: '/getDeletedList',
                noError: true,
                pagination: {
                    page: delListsPage
                }
            })

            response && response.data ?
            setDeletedLists( response.data.map( ({name, details, totalContact, id}) => ({
                title: name,
                description: details,
                totalContacts: totalContact,
                id
            }) ) ) : setDeletedLists([])

            setTotalDelLists(response?.total)
        }
        catch(err){
            console.log(err.message)
            // toast.error(err.message)
        }
        finally{
            setDeletedListsLoading(false)
        }
    }

    useEffect( () => {
        fetchDeletedMails()
    }, [reloadDeletedMails, delMailsPage] )

    useEffect( () => {
        fetchDeletedLists()
    }, [reloadDeletedLists, delListsPage] )


    const tabs = [
        {
            title: 'DELETED MAILS',
            id: 'mails',
            action: () => setCurrentTab('mails')
        },
        {
            title: 'DELETED LISTS',
            id: 'lists',
            action: () => setCurrentTab('lists')
        }
    ]

    return (
        <RecycleBinContext.Provider
        value={{
            setReloadDeletedLists,
            setReloadDeletedMails,
            delMailsPage, setDelMailsPage,
            delListsPage, setDelListsPage,
            totalDelLists, setTotalDelLists,
            totalDelMails, setTotalDelMails
        }}
        >
        <Stack
            sx={{
                // alignSelf: 'center',
                p: !sm ? '50px 0 0 0' : '20px 0 0 0',
                gap: !sm ? '50px' : '30px',
                ...sm && {width: '100%'}
            }}
            >
            <Stack
            sx={{
                width: '100%'
            }}
            >
            {/* <Search /> */}
            </Stack>
            <Stack
            direction='row'
            >
                {
                tabs.map( ({title, id, action}, k) => (
                    <Stack
                    key={k}
                    onClick={action}
                    sx={{
                        borderBottom: '1px solid',
                        borderColor: currentTab === id ? 'primary.main' : 'neutral.900',
                        p: !sm ? '15px 50px' : '15px 10px',
                        cursor: 'pointer',
                        ...sm && {width: '50%'}
                    }}
                    >
                    <Typography
                    sx={{
                        color: currentTab === id ? 'primary.main' : 'neutral.900',
                        fontWeight: 500,
                    }}
                    >
                        {title}
                    </Typography>
                    </Stack>
                ) )
                }
            </Stack>

            <Stack
            sx={{
                // p: '20px',
                // ...(currentTab === 'schedule' && !sm) && {boxShadow: '0 0 10px rgb(0,0,0,.125)'},
                // borderRadius: '15px',
                // alignSelf: 'center'
            }}
            >
            {
                currentTab === 'mails' &&
                <Stack >
                <DeletedMails 
                mails={deletedMails}
                loading={deletedMailsLoading}
                />
                </Stack>
            }
            {
                currentTab === 'lists' &&
                <Stack>
                <DeletedLists
                lists={deletedLists}
                loading={deletedListsLoading}
                />
                </Stack>
            }
            </Stack>
            </Stack>
            </RecycleBinContext.Provider>
    )
}

export default Bin