import { Alert, Checkbox, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material"
import Layout from "../Layout"
import { useState } from "react"
import { useEffect } from "react"
import LoginPageContext from "../../../contexts/loginPageContext"
import { neutral, primary } from "../../../theme/palette"
import CustomButton from "../../Button"
import { useBreakpoints } from "../../../theme/breakpoints"
import { useLocation, useNavigate } from "react-router-dom"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { fetchData } from "../../../utils/functions/fetchData"
import { waitForTimeout } from "../../../utils/functions/waitFunctions"
import Logo from "../../Logo"



const LoginPage = () => {
    const location = useLocation()
    const redirectAfterLogin = location?.state?.source || '/dashboard'


    const {xs, sm, md, lg, xl} = useBreakpoints()
    const navigate = useNavigate()

    let loginLocalData = localStorage.getItem('login')
    if(loginLocalData){
        loginLocalData = JSON.parse(loginLocalData)
    }

    const initialLoginDetails = {
        email: loginLocalData ? loginLocalData?.email : '',
        pass: loginLocalData ? loginLocalData?.pass : '',
        remember: loginLocalData ? loginLocalData?.remember : false
    }
    const nullLoginDetails = {
        email: '',
        pass: '',
        remember: false
    }
    const [loginDetails, setLoginDetails] = useState(initialLoginDetails)


    useEffect( () => {
        const dataToStore = JSON.stringify(loginDetails)
        localStorage.setItem('login', dataToStore)
    }, [loginDetails] )


    const [loading, setLoading] = useState(false)
    const [resMessage, setResMessage] = useState({success: false, message: ''})


    const handleSubmit = async (e) => {
        e?.preventDefault()
        setLoading(true)
        try{
            const {data: loginData, message} = await fetchData({
                endpoint: '/login',
                reniAuth: true,
                payload: {
                    "mail": loginDetails?.email,
                    "pword": loginDetails?.pass,
                    usertoken: undefined
                }
            })
            setResMessage( (prevVal) => ({...prevVal, message, success: true}) )
            localStorage.setItem(
                'userData',
                JSON.stringify({
                  email: loginData?.email,
                  token: loginData?.usertoken,
                  fullname: loginData?.fullname,
                  username: loginData?.username,
                  phonenumber: loginData?.phone,
                  picture: loginData?.photo,
                  isBVN: loginData?.isBVN
                })
            )

            waitForTimeout(1500, () => {
                !(loginDetails?.remember) && setLoginDetails(nullLoginDetails)
                
                waitForTimeout(100, () => {
                    // window.location.href='/dashboard'
                    navigate(redirectAfterLogin)
                })
            })
        }
        catch(err){
            console.log(err.message)
            setResMessage( (prevVal) => ({...prevVal, message: err.message, success: false}) )
        }
        finally{
            setLoading(false)
        }
    }







    const clearResponseMessage = () => {
        setResMessage( (prevVal) => ({...prevVal, message: ''}) )
    }


    const form = [
        {
            title: 'Email',
            value: loginDetails?.email,
            onChange: (e) => setLoginDetails( (prevVal) => ({...prevVal, email: e.target.value}) )
        },
        {
            title: 'Password',
            value: loginDetails?.pass,
            onChange: (e) => setLoginDetails( (prevVal) => ({...prevVal, pass: e.target.value}) ),
            password: true,
        },
    ]
    

    return (
        <Layout>
        <LoginPageContext.Provider
        value={{
            
        }}
        >
        <Stack
        sx={{
            p: sm ? '50px 20px' : '50px',
            alignItems: 'center',
            bgcolor: 'neutral.50',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0,0,0,.125)',
            width: xs ? '97%' : sm ? '95%' : md ? '70%' : lg ? '50%'  : '40%',
            gap: '30px',
            height: 'fit-content'
        }}
        >
        <Stack
        sx={{
            textAlign: 'center',
            alignItems: 'center'
        }}
        >
        <Logo />
        </Stack>

        <Typography variant="h5" sx={{color: primary.main}}>
        Login Into ReniMail
        </Typography>
        
        <form
        noValidate
        onSubmit={handleSubmit}
        style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
            width: '100%'
        }}
        >
        {
        resMessage?.message &&
        <Alert
        severity={resMessage?.success ? "success" : "error"}
        >
        <Typography>{resMessage?.message}</Typography>
        </Alert>
        }
        {
        form.map( ({title, value, onChange, password}, k) => (
            <Stack
            key={title}
            sx={{width: '100%'}}>
            <Typography>
            {title}
            </Typography>
            <Input 
            value={value}
            password={password}
            onChange={onChange}
            clearResponseMessage={clearResponseMessage}
            />
            </Stack>
        ) )
        }

        <Stack 
        direction={xs ? 'column' : 'row'}
        sx={{
            ...!xs && {
                justifyContent: 'space-between', 
                alignItems: 'center'
            }
        }}>
        <Stack 
        direction='row'
        sx={{gap: '5px', alignItems: 'center'}}
        >
        <Checkbox
        checked={loginDetails?.remember}
        onChange={ (_, checked) => setLoginDetails( (prevVal) => ({...prevVal, remember: checked}) ) }
        />
        <Typography>
        Remember Me
        </Typography>
        </Stack>

        <CustomButton
        title='Forgot Password'
        variant="outlined"
        component='span'
        noBorder
        sx={{
            display: 'inline-flex',
            textTransform: 'unset',
            alignSelf: 'flex-start'
        }}
        onClick={ () => navigate('/forgot-password') }
        />
        </Stack>

        <CustomButton 
        title='Login'
        type='submit'
        variant="contained"
        squareCorners
        onClick={handleSubmit}
        loading={loading}
        sx={{
            alignSelf: 'center',
            textTransform: 'unset',
            p: '10px 20px'
        }}
        />
        </form>
        
        <Typography>
        <span>Not  a member?</span>
        <CustomButton
        title='Sign Up'
        variant="outlined"
        component='span'
        noBorder
        sx={{
            display: 'inline-flex'
        }}
        onClick={ () => navigate('/register') }
        />
        </Typography>
        </Stack>
        </LoginPageContext.Provider>
        </Layout>
    )
}

export default LoginPage












const Input = ({value, password, onChange, readOnly, clearResponseMessage}) => {

    const [showPassword, setShowPassword] = useState(false)

    return (
        <TextField
        fullWidth
        type={ password ? (showPassword ? 'text' : 'password') : 'text' }
        value={value}
        onChange={onChange}
        onFocus={clearResponseMessage}
        sx={{
            '& input': {
                p: '10px 15px',
                ...readOnly && {
                    color: 'neutral.500'
                }
            },
            '& .MuiInputBase-root': {
                ...readOnly && {
                    bgcolor: 'neutral.200',
                }
            }
        }}
        InputProps={{
        endAdornment: (
            <>
            {
                password &&
                <InputAdornment position="end">
                    <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => setShowPassword(!showPassword)}
                    >
                        {
                            showPassword ?
                            <FaEyeSlash size={15} /> :
                            <FaEye size={15} />
                        }
                    </IconButton>
                </InputAdornment>
            }
            </>
        ),
    }}
        /> 
    )
}