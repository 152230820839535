import { Avatar,CircularProgress,Collapse,Dialog,DialogActions,DialogTitle,IconButton, InputAdornment, Skeleton, Stack, TextField, Typography } from "@mui/material"
import { useLayoutContext } from "../../../../contexts/layoutContext"
import { useEffect, useState } from "react"
import { MdDelete, MdEdit, MdSettings } from "react-icons/md"
import Button from "../../../Button"
import { toast } from "react-hot-toast"
import { useBreakpoints } from "../../../../theme/breakpoints"
import { fetchData, isBVN} from "../../../../utils/functions/fetchData"
import { updateLocalStorage } from "../../../../utils/functions/updateStorage"
import { FaInfoCircle, FaUserCog } from "react-icons/fa"
import { AiFillBank } from "react-icons/ai"
import { IoMdAdd, IoMdWallet } from "react-icons/io"
import {GoDotFill} from 'react-icons/go'
import CustomEmailDNS from "./CustomEmailDNS"
import { GrUpgrade } from "react-icons/gr"
import InfoView from "../../InfoView"
import { primary } from "../../../../theme/palette"
import copyToClipboard from "../../../../utils/functions/copyToClipboard"

const textFieldStyle = {
    minWidth: '50%',
    '& .MuiInputBase-root': {
        borderRadius: '8px'
    },
    '& .Mui-focused': {
        // border: 'unset',
    },
    '& input': {
        bgcolor: 'white',
        color: '#000',
        p: '5px 15px',
        minHeight: '30px'
    },
    '& input:hover': {
        borderColor: 'primary.main'
    },
}


const Account = () => {
    const {userData, setUserData} = useLayoutContext()
    const [imageLoading, setImageLoading] = useState(false)
    const {sm} = useBreakpoints()

    const [showNewCMailFields, setShowNewCMailFields] = useState(false)
    const [customEmails, setCustomEmails] = useState([]);
    const [customEmailLoading, setCustomEmailsLoading] = useState(true)
    const [reload, setReload] = useState()
    

    const {
        userBalance,
        reniUserDetails,
        reniUserDetailsLoading,
        userSub,
        userSubLoading
    } = useLayoutContext()


    const handleImageChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            setImageLoading(true)
            const reader = new FileReader();
            reader.onloadend = async () => {
                try{
                    const {message: imageUploadMessage} = await fetchData({
                        endpoint: '/changePicture',
                        payload: {
                            photo: reader.result
                        }
                    })

                    setUserData( (prevVal) => ({...prevVal, picture: reader.result}) )
                    updateLocalStorage('userData', {picture: reader.result})
                    toast.success(imageUploadMessage)
                }
                catch(err){
                    console.error(err.message)
                    toast.error(err.message)
                }
                finally{
                    setImageLoading(false)
                }
            }
            reader.readAsDataURL(file)
        }
    }

    const fetchCustomEmails = async () => {
        try{
            setCustomEmailsLoading(true)
            const {data} = await fetchData({
                endpoint: '/getCustomMails',
                noError: true,
                version: 3,
                pagination: {
                    page: 1
                },
            })
            
            setCustomEmails(data.map( (customEmail) => ({
                id: customEmail.custom_mail_id,
                server: customEmail.server,
                email: customEmail.email,
                password: '******'
            }) ))
        }
        catch(err){
            console.log(err.message)
        }
        finally{
            setCustomEmailsLoading(false)
        }
    }

    const reloadCustomEmails = () => setReload(!reload)

    useEffect( () => {
        fetchCustomEmails()
    }, [reload] )


    const personalInfoData = [
        {
            title: 'Full Name',
            value: userData?.fullname,
            onChange: (e) => setUserData( (prevVal) =>  ({...prevVal, fullname: e.target.value}))
        },
        {
            title: 'Email Address',
            value: userData?.email,
            onChange: (e) => setUserData( (prevVal) =>  ({...prevVal, email: e.target.value}))
        },
        {
            title: 'Username',
            value: userData?.username,
            onChange: (e) => setUserData( (prevVal) =>  ({...prevVal, username: e.target.value}))
        },
        {
            title: 'Phone number',
            value: userData?.phonenumber,
            onChange: (e) => setUserData( (prevVal) =>  ({...prevVal, phonenumber: e.target.value}))
        }
    ]

    const acctDetails = [
        {
            title: 'acct-no',
            value: reniUserDetails?.nuban,
            Icon: FaUserCog
        },
        {
            title: 'bank-name',
            value: reniUserDetails?.bankname,
            Icon: AiFillBank
        },
        {
            title: 'balance',
            value: userBalance?.value ? `₦ ${userBalance?.value}` : 'Unavailable at the moment',
            Icon: IoMdWallet
        },
    ]

    

    return (
        <Stack sx={{gap: '30px'}}>
            <Stack
            direction={sm ? 'column' : 'row'}
            sx={{
                justifyContent: 'space-between',
                ...!sm && {alignItems: 'center'},
                ...sm && {gap: '20px'}
            }}
            >
            <Stack direction="row" sx={{gap: '20px', alignItems: 'center'}}>
                <label htmlFor="image-input">
                {
                !imageLoading ?
                <Avatar
                src={userData?.picture}
                sx={{
                border: '3px solid',
                borderColor: 'primary.main',
                width: '70px',
                height: '70px',
                cursor: 'pointer',
                }}
                /> : 
                <Stack>
                <Skeleton 
                width='70px' 
                height='70px' 
                variant="circular"
                
                />
                <Typography variant="caption">
                uploading...
                </Typography>
                </Stack>
                }
                </label>
                <input
                id="image-input"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={ handleImageChange }
                />

                <Stack>
                <Typography variant="h5" sx={{fontWeight: '500'}}>
                    {userData?.fullname}
                </Typography>
                <Stack
                direction='row'
                sx={{
                    gap: '3px',
                    fontSize: '.8rem',
                    alignItems: 'center'
                }}
                >
                    <Typography>UserID&nbsp;:</Typography>
                    <Typography
                    component={IconButton}
                    onClick={() => copyToClipboard(userData?.token, 'UserID copied to clipboard')}
                    sx={{
                        fontSize: '.9rem',
                        fontWeight: '600',
                        p: '3px 5px',
                        borderRadius: '3px',
                        bgcolor: 'neutral.200',
                        color: 'neutral.900'
                    }}
                    >
                    {userData?.token}
                    </Typography>
                </Stack>
                </Stack>
            </Stack>

            {
            isBVN() &&
            <Stack
            sx={{
                bgcolor: 'neutral.200',
                borderRadius: '8px',
                p: '5px 8px',
                minWidth: '200px',
                width: 'fit-content',
                gap: '7px'
            }}
            >
                {
                acctDetails.map( ({title, Icon, value}) => (
                    <AcctDetail
                    key={title}
                    value={value}
                    Icon={Icon}
                    loading={reniUserDetailsLoading || userBalance?.loading}
                    />
                ) )
                }
            </Stack>
            }
            </Stack>
            <Stack sx={{gap: '10px'}}>
            <Typography variant="h5" sx={{fontWeight: '600'}}>
                Personal Info
            </Typography>
            <Stack sx={{gap: '15px'}}>
            {
                personalInfoData.map( ({title, value, onChange}, k) => (
                    <UserInput 
                    key={k}
                    title={title}
                    value={value}
                    onChange={onChange}
                    disabled
                    />
                ) )
            }
            </Stack>
            {/* <Button 
            title='Save'
            Icon={MdOutlineSave}
            loading={loading}
            sx={{
                mt: '20px'
            }}
            onClick={handleSaveInfo}
            /> */}
            </Stack>


            {
            userSubLoading ? 
            <CircularProgress 
            size={30} 
            sx={{mx: 'auto', mt: '50px'}}
            /> :
            (
                !(userSub?.isPremiumUser && Number(userSub?.packageid)>1) ?
                <Stack sx={{gap: '10px'}}>
                <Typography variant="h5" sx={{fontWeight: '600'}}>
                    Custom Emails
                </Typography>
    
                <Typography textAlign={'center'}>Upgrade your plan to have access to custom emails</Typography>
                <Button
                title='Upgrade'
                link
                linkProps={{to: '/dashboard/settings/pricing'}}
                Icon={GrUpgrade}
                sx={{
                    mt: '20px',
                    borderRadius: '5px',
                    alignSelf: 'center',
                    color: '#fff !important'
                }}
                
                />
                </Stack>
                :
                
                <Stack sx={{gap: '10px'}}>
                <Typography variant="h5" sx={{fontWeight: '600'}}>
                    Custom Emails
                </Typography>
    
    
                {
                !showNewCMailFields && customEmails.length===0 &&
                <>
                <Typography sx={{textAlign: 'center'}}>
                    You have no custom email<br/><GoDotFill />
                </Typography>
                </>
                }
    
                <Stack sx={{gap: '20px'}}>
                {
                customEmailLoading ? 
                Array.from({length: 3}).map( (_, k) => (
                    <CustomEmail 
                    key={k}
                    loading={true}
                    />
                ) )
                :
                customEmails.map( (customEmail, k) => (
                    <CustomEmail 
                    key={k}
                    index={k+1}
                    customEmailDetails={customEmail}
                    reloadCustomEmails={reloadCustomEmails}
                    />
                ) )
                }
                {
                !showNewCMailFields &&
                <Button
                title='Add New'
                Icon={IoMdAdd}
                sx={{
                    mt: '20px',
                    borderRadius: '5px',
                    alignSelf: 'center'
                }}
                onClick={() => setShowNewCMailFields(true)}
                />
                }
                </Stack>
    
                {
                showNewCMailFields &&
                <CustomEmailInputs
                setShowNewCMailFields={setShowNewCMailFields}
                reloadCustomEmails={reloadCustomEmails}
                />
                }
                </Stack>
            )
            }
        </Stack>
    )
}

export default Account



const UserInput = ({title, value, onChange, disabled}) => {
    const [enableEdit, setEnableEdit] = useState(false)
    const {xs} = useBreakpoints()
    

    return (
        <Stack 
        direction={ xs ? "column" : "row"}
        sx={{justifyContent: 'space-between', gap: '10px'}}
        >
        <Typography>{title}</Typography>
        <TextField
        sx={textFieldStyle}
        value={value}
        onChange={onChange}
        disabled={disabled || !enableEdit}


        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => setEnableEdit(!enableEdit)}
                    >
                        <MdEdit size={18} />
                    </IconButton>
                </InputAdornment>
            ),
        }}
        />
        </Stack>
    )
}


const AcctDetail = ({value, Icon, loading}) => (
    <Stack
    direction='row'
    sx={{
        width: '100%',
        gap: '7px',
        p: '5px 8px',
        borderRadius: '25px',
        bgcolor: 'neutral.100',
        alignItems: 'center',
    }}
    >
    <Icon size={20} />
    {
    !loading ? <Typography>{value}</Typography> : <Skeleton width={50} />
    }
    </Stack>
)



const CustomEmailInput = ({title, value, type, onChange, disabled, toCreate=true}) => {
    const {xs, sm} = useBreakpoints()
    const [openInfo, setOpenInfo] = useState(false)


    const INFO = () => (
        <Typography sx={{ fontWeight: 500}}>
        <i>
        {
            title==='Server' ?
            `This refers to the email server of your domain name. If you don't have this, you can get this from your web hosting provider.`:
            title.includes('Port') ?
            `This refers to the port for your IMAP incoming email. If you don't have this, you can get this from your web hosting provider.`:
            null
          }
        </i>
        </Typography>
    )
    

    return (
        <>
        <Stack 
        direction={ xs ? "column" : "row"}
        sx={{justifyContent: 'space-between', gap: '10px'}}
        >
        <Stack
        direction='row'
        sx={{gap: '10px', alignItems: 'center'}}
        >
        <Typography>{title}</Typography>
        {
        toCreate &&
        (title==='Server' || title?.includes('Port')) &&
        <IconButton
        sx={{width: 'fit-content', color: primary.main}}
        onClick={() => setOpenInfo(true)}
        >
        <FaInfoCircle size={sm ? 14 : 18} />
        </IconButton>
        }
        </Stack>
        <TextField
        sx={textFieldStyle}
        type={type || 'text'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        />
        </Stack>



        {
        toCreate &&
        <>
        <InfoView
        dialog
        open={openInfo}
        setOpen={setOpenInfo}
        >
        <INFO />
        </InfoView>
        </>
        }
        </>
    )
}


const CustomEmailInputs = ({
    customEmailDetails, 
    create=true, 
    setShowNewCMailFields,
    reloadCustomEmails
}) => {

    const [loading, setLoading] = useState(false)

    const initialCustomEmail = {
        id: customEmailDetails?.id || '',
        server: customEmailDetails?.server || '',
        email: customEmailDetails?.email || '',
        password: customEmailDetails?.password || '',
        port: customEmailDetails?.port || '',
    }
    const [customEmail, setCustomEmail] = useState(initialCustomEmail)

    const customEmailData = [
        {
            title: 'Server',
            value: customEmail.server,
            type: 'text',
            onChange: (val) => setCustomEmail( (prevVal) => ({...prevVal, server: val}) )
        },
        {
            title: 'Port',
            value: customEmail.port,
            type: 'number',
            onChange: (val) => setCustomEmail( (prevVal) => ({...prevVal, port: val}) )
        },
        {
            title: 'Email',
            value: customEmail.email,
            type: 'email',
            onChange: (val) => setCustomEmail( (prevVal) => ({...prevVal, email: val}) )
        },
        {
            title: 'Password',
            value: customEmail.password,
            type: 'text',
            onChange: (val) => setCustomEmail( (prevVal) => ({...prevVal, password: val}) )
        },
    ]


    const handleCreateCustomEmail = async (e) => {
        e.preventDefault()
        try{
            setLoading(true)
            if(!customEmail.email || !customEmail.password || !customEmail.server){
                return toast.error('Provide all details')
            }
            
            const {message} = await fetchData({
                endpoint: '/addCustomMail',
                reqMethod: 'post',
                version: 3,
                payload: {
                    "server": customEmail.server,
                    "port": customEmail.port ||  undefined,
                    "email": customEmail.email,
                    "pword": customEmail.password,
                }
            })
            toast.success(message)
            setCustomEmail(initialCustomEmail)
            setShowNewCMailFields && setShowNewCMailFields(false)
            reloadCustomEmails && reloadCustomEmails()
        }
        catch(err){
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }

    const handleUpdateCustomEmail = async (e) => {
        e.preventDefault()
        try{
            setLoading(true)
            if(!customEmail.password){
                return toast.error('Provide password')
            }

            const {message} = await fetchData({
                endpoint: '/updateCustomMailPword',
                version: 3,
                payload: {
                    "custom_mail_id": customEmail.id,
                    "pword": customEmail.password,
                }
            })
            toast.success(message)
            reloadCustomEmails && reloadCustomEmails()
        }
        catch(err){
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }


    return (
        <Stack
        component='form'
        onSubmit={create ? handleCreateCustomEmail : handleUpdateCustomEmail}
        sx={{gap: '15px'}}
        >
        {
            customEmailData.map( ({title, value, onChange, type}, k) => (
                <CustomEmailInput
                key={k}
                title={create&&title==='Port' ? `${title} (optional)` : title}
                value={value}
                onChange={onChange}
                type={type}
                disabled={!create && title!=='Password'}
                toCreate={create}
                />
            ) )
        }
        <Button 
        title={create ? 'Create' : 'Update'}
        type='submit'
        loading={loading}
        sx={{
            mt: '20px',
            borderRadius: '5px',
        }}
        />
        </Stack>
    )
}


const CustomEmail = ({customEmailDetails, index, loading, reloadCustomEmails}) => {
    const [enableEdit, setEnableEdit] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const [customEmailDns, setCustomEmailDns] = useState([])
    const [dnsRecordLoading, setDnsRecordLoading] = useState(false)
    const [openDnsRecord, setOpenDnsRecord] = useState(false)

    const handleDelete = async () => {
        try{
            setDeleteLoading(true)
            const {message} = await fetchData({
                endpoint: '/deleteCustomMail',
                version: 3,
                payload: {
                    "custom_mail_id": customEmailDetails.id,
                }
            })
            toast.success(message)
            reloadCustomEmails && reloadCustomEmails()
        }
        catch(err){
            toast.error(err.message)
        }
        finally{
            setDeleteLoading(false)
        }
    }

    const handleFetchDnsRecord = async () => {
        try{
            setDnsRecordLoading(true)
            const {data} = await fetchData({
                endpoint: '/getCustomMailDNSRecord',
                payload: {customMailID: customEmailDetails.id},
                version: 3
            })
            console.log('Main Data', data)
            setCustomEmailDns(data)
        }
        catch(err){
            console.log(err.message)
            toast.error(err.message)
        }
        finally{
            setDnsRecordLoading(false)
        }
    }

    useEffect( () => {
        if(openDnsRecord){
            handleFetchDnsRecord()
            setEnableEdit(false)
            setOpenDeleteModal(false)
        }
    }, [openDnsRecord] )

    useEffect( () => {
        if(openDeleteModal){
            setEnableEdit(false)
            setOpenDnsRecord(false)
        }
    }, [openDeleteModal] )

    useEffect( () => {
        if(enableEdit){
            setOpenDnsRecord(false)
            setOpenDeleteModal(false)
        }
    }, [enableEdit] )

    return (
        <>
        <Stack>
        <Stack
        direction='row'
        sx={{
            justifyContent: 'space-between'
        }}
        >
        {
        !loading ?
        <Typography>
            {index}. &nbsp;&nbsp;
            <Typography 
            component='span'
            >
            {customEmailDetails?.email}
            </Typography>
        </Typography> : 
        <Skeleton width={100} height={30} />
        }

        
        <Stack direction='row' sx={{gap: '20px'}}>
        {
        !loading ?
        <IconButton
        edge="end"
        aria-label="edit"
        onClick={() => setOpenDnsRecord(!openDnsRecord)}
        >
            <MdSettings color="#999999" size={25} />
        </IconButton> : 
        <Skeleton width={50} height={30} />
        }
        {
        !loading ?
        <IconButton
        edge="end"
        aria-label="edit"
        onClick={() => setEnableEdit(!enableEdit)}
        >
            <MdEdit color="#4285F4" size={25} />
        </IconButton> : 
        <Skeleton width={50} height={30} />
        }
        {
        !loading ?
        <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => setOpenDeleteModal(true)}
        >
            <MdDelete color="red" size={25} />
        </IconButton> :
        <Skeleton width={50} height={30} />
        }
        </Stack>
        </Stack>

        <Collapse
        in={enableEdit}
        >
        <CustomEmailInputs
        create={false}
        customEmailDetails={customEmailDetails}
        reloadCustomEmails={reloadCustomEmails}
        />
        </Collapse>
        <Collapse
        in={openDnsRecord}
        >
        <CustomEmailDNS
        data={customEmailDns}
        loading={dnsRecordLoading}
        />
        </Collapse>
        </Stack>



        <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        sx={{
            '& .MuiPaper-root': {
                bgcolor: 'neutral.50'
            }
        }}
        >
            <DialogTitle component='span'>
            <Typography
            sx={{
                fontSize: '1.1rem'
            }}
            >
            {`Are you sure you want to delete this custom email?`}
            </Typography>
            </DialogTitle>
            <DialogActions>
            <Button
            onClick={() => setOpenDeleteModal(false)}
            disabled={deleteLoading}
            sx={{
                borderRadius: '5px',
                color: 'neutral.50',
                bgcolor: '#6C737F',
                '&: hover': {
                    bgcolor: '#4D5761'
                }
            }}
            >
                Cancel
            </Button>
            <Button
            autoFocus
            loading={deleteLoading}
            sx={{
                borderRadius: '5px',
                color: 'neutral.50',
                bgcolor: 'red',
                '&: hover': {
                    bgcolor: '#8B0000'
                }
            }}
            onClick={() => handleDelete()}
            >
                Delete
            </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}