import { useState } from "react";
import CustomButton from "../../Button";
import { fetchData } from "../../../utils/functions/fetchData";
import { toast } from "react-hot-toast";
import { useEmailsPageContext } from "../../../contexts/emailsPageContext";
import { useNavigate } from "react-router-dom";

const { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } = require("@mui/material");



const DeleteEmail = ({ open,setOpen, emailData}) => {

    const onClose = () => setOpen(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const {currentTab, 
      setReloadEmails, 
      setReloadArchivedEmails, 
      setReloadDrafts,
      viewEmailDetails} = useEmailsPageContext()

    const handleDelete = async () => {
        setLoading(true)
        try{
            currentTab !== 'draft' ?
            await fetchData({
              endpoint: '/deleteMail',
              payload: {mlistid: emailData?.id}
            }) :
            await fetchData({
              endpoint: '/deleteDraftMail',
              payload: {draftId: emailData?.id}
            })

            toast.success(`Email "${emailData?.subject}" deleted`)
            if(viewEmailDetails){
              return navigate(-1)
            }
            if(currentTab === 'overview'){
              setReloadEmails((prevValue) => !prevValue)
            }
            if(currentTab === 'archived-emails'){
              setReloadArchivedEmails((prevValue) => !prevValue)
            }
            if(currentTab === 'draft'){
              setReloadDrafts((prevValue) => !prevValue)
            }
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }


    return (
      <Dialog 
      open={open} 
      onClose={!loading && onClose}
      sx={{
        '& .MuiPaper-root': {
            bgcolor: 'neutral.50'
        }
      }}
      >
        <DialogTitle >
        <Typography variant="h4">
        {`Are you sure you want to delete email "${emailData?.subject}" ?`}
        </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
            Deleting the email will dump it in recycle bin. You can restore it within 90 days.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton
          onClick={onClose}
          sx={{
            bgcolor: 'neutral.700',
            '&: hover': {
                bgcolor: 'neutral.800'
            }
          }}
          >
            Cancel
          </CustomButton>
          <CustomButton
          loadingText='Deleting email'
          loading={loading}
          onClick={handleDelete}
          autoFocus
          sx={{
            bgcolor: '#ff0000',
            '&: hover': {
                bgcolor: '#cc0000'
            }
          }}
          >
            Delete
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
};
  

export default DeleteEmail


