import { TbArrowLeft } from "react-icons/tb"
import CustomButton from "./Button"
import { useNavigate } from "react-router-dom"
import { SxProps } from "@mui/material"

interface IBackButton {
    sx: SxProps;
}

const BackButton = ({sx}: IBackButton) => {

    const navigate = useNavigate()

    return (
        <CustomButton
        title='Back'
        Icon={TbArrowLeft}
        IconPlacement="left"
        sx={{gap: '3px', alignSelf: 'flex-start', ...sx}}
        onClick={ () => navigate(-1) }
        />
    )
}

export default BackButton