import { useEffect, useState } from "react";
import { BsBank2, BsCheckLg, BsCreditCardFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { neutral } from "../../../../../../theme/palette";
import { useBreakpoints } from "../../../../../../theme/breakpoints";
import SettingsPageContext, { useSettingsPageContext } from "../../../../../../contexts/settingsPageContext";
import CardInputs from "./CardInputs";
import ReniTrust from "./ReniTrust";
import { fetchData } from "../../../../../../utils/functions/fetchData";
import { toast } from "react-hot-toast";
import OTPScreen from "./OTPScreen";
import AVSScreen from "./AVSScreen";
import FlutterwaveIcon from "../../../../../../icons/Flutterwave";
import Flutterwave from "./Flutterwave";

const { Dialog, Typography, Stack } = require("@mui/material");




const PaymentModal = ({ open,setOpen, planDetails}) => {
    const {sm} = useBreakpoints()
    const [currentTab, setCurrentTab] = useState('reni-trust')
    const initialPaymentDetails = {
        cardNumber: '',
        expMonth: '',
        cvv: '',
        expYear: '',
        pin: '',
        otp: '',
        avs: '',
        transactionRef: '',
        state: '',
        city: '',
        country: '',
        address: '',
        zipCode: ''
    }
    const [paymentDetails, setPaymentDetails] = useState(initialPaymentDetails)
    const {reniUserDetails, reniUserDetailsLoading} = useSettingsPageContext()

    const [openOTPModal, setOpenOTPModal] = useState(false)
    const [openAVSModal, setOpenAVSModal] = useState(false)


    const tabOptions = (!openOTPModal && !openAVSModal) ? 
    [
        {
            title: 'ReniTrust',
            Icon: BsBank2,
            id: 'reni-trust'
        },
        {
            title: 'Card',
            Icon: BsCreditCardFill,
            id: 'card'
        },
        {
            title: 'Flutterwave',
            Icon: FlutterwaveIcon,
            id: 'flutterwave'
        },
    ] :
    [
        {
            title: 'Card',
            Icon: BsCreditCardFill,
            id: 'card'
        }        
    ]

    const onClose = () => setOpen(false)

    return (
    <SettingsPageContext.Provider
    value={{
        currentTab,
        setCurrentTab,
        paymentDetails,
        setPaymentDetails,
        planDetails,
        reniUserDetails,
        reniUserDetailsLoading,
        setOpenOTPModal,
        setOpenAVSModal
    }}
    >
      <Dialog
      open={open}
    //   onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
            bgcolor: 'neutral.50',
        }
      }}
      >
        <Stack
        direction={sm ? 'column' : 'row'}
        >
            <Stack sx={{
                gap: '20px',
                p: '20px 10px',
                bgcolor: '#d5e6fb'
            }}>
            <Typography variant="h6" sx={{whiteSpace: 'nowrap'}}>
                SELECT A PAYMENT METHOD
            </Typography>
            
            {
                tabOptions.map( ({title, Icon, id}) => (
                    <Tab 
                    title={title}
                    Icon={Icon}
                    id={id}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    />
                ) )
            }
            </Stack>
            
            <Stack
            sx={{
                // ...!sm && {width: '500px'},
                p: '7px 20px 50px 20px',
            }}
            >
            <Stack sx={{alignSelf: 'flex-end', cursor: 'pointer'}} onClick={onClose}>
            <IoClose size={30}  />
            </Stack>
            {
                currentTab==='card' &&
                <>
                {
                    (!openOTPModal && !openAVSModal) ?
                    <CardInputs /> :

                    openOTPModal ?
                    <OTPScreen /> :
                    openAVSModal ?
                    <AVSScreen /> : 
                    <CardInputs />
                }
                </>
            }

            {
                currentTab==='reni-trust' &&
                <ReniTrust />
            }
            {
                currentTab==='flutterwave' &&
                <Flutterwave />
            }
            </Stack>
        </Stack>
      </Dialog>
    </SettingsPageContext.Provider>
    );
};
  

export default PaymentModal



const Tab = ({title, Icon, id, currentTab, setCurrentTab}) => {


    return (
        <Stack
        direction='row'
        sx={{
            borderRadius: '25px',
            ...currentTab===id && {boxShadow: '0 0 10px rgb(0,0,0,.125)', bgcolor: 'neutral.50'},
            p: '7px 10px',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer'
        }}
        onClick={ () => setCurrentTab(id) }
        >
        <Stack
        direction='row'
        sx={{gap: '15px', alignItems: 'center'}}
        >
        <Icon size={20} color='#17770c' />
        <Typography>{title}</Typography>
        </Stack>

        {
            currentTab===id &&
            <Stack sx={{bgcolor: 'primary.main', width: '22px', height: '22px', borderRadius: '50%', alignItems: 'center', justifyContent: 'center'}}>
                <BsCheckLg size={17} color={neutral[50]} />
            </Stack>
        }
        </Stack>
    )
}