import { Stack } from "@mui/material"
import Layout from "../../../Layouts/DashboardLayout"
import ContactTable from "./ContactTable"
import { useLayoutContext } from "../../../../contexts/layoutContext"
import { useBreakpoints } from "../../../../theme/breakpoints"
import BackButton from "../../../BackButton"
import ListPageContext from "../../../../contexts/listPageContext"
import { useState } from "react"



const ViewContacts = () => {
    const {miniSideBar} = useLayoutContext()
    const {mdOnly, sm} = useBreakpoints()
    const [reloadContacts, setReloadContacts] = useState(false)

    const handleReloadContacts = () => {
        setReloadContacts( (prevVal) => !prevVal )
    }

    return (
        <ListPageContext.Provider
        value={{
            viewContacts: true,
            reloadContacts,
            setReloadContacts,
            handleReloadContacts,
        }}
        >
        <Stack
        sx={{
            p: (!miniSideBar && mdOnly) ? '50px 20px' : sm ? '15px' : '50px 50px',
            gap: '30px'
        }}
        >
        <BackButton />
        
        <ContactTable
        />
        </Stack>
        </ListPageContext.Provider>
    )
}



const ViewContactsPage = () => {

    return (
        <Layout>
            <ViewContacts />
        </Layout>
    )
}


export default ViewContactsPage