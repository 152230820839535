import { Pagination, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import { FaClipboardList } from "react-icons/fa"
import { neutral } from "../../../../theme/palette"
import TableDivider from "../../../TableDivider"
import { fetchData, perPage } from "../../../../utils/functions/fetchData"
import { useBreakpoints } from "../../../../theme/breakpoints"
import { IoStatsChart } from "react-icons/io5"



const ClicksTable = ({mailId}) => {
    const {sm} = useBreakpoints()
    const [tableData, setTableData] = useState([])
    const [totalContacts, setTotalContacts] = useState(0)
    const [contactsPage, setContactsPage] = useState(1)


    const [loading, setLoading] = useState(false)

    useEffect( () => {
        setLoading(true)
        const handleLoadData = async () => {
        try{
            const response = await fetchData({
                endpoint: '/getClickContacts',
                payload: {mailId},
                pagination: {
                    page: contactsPage
                },
                noError: true
            })

            response && response.data ?
            setTableData(
                response.data.map( ({name, mail, clicks}) => ({
                name,
                email : mail,
                clicks
                }) ) ) : setTableData([])

                setTotalContacts(response?.total)
        }
        catch(err){
            console.error(err.message)
        }
        finally{
            setLoading(false)
        }
        }
        
        handleLoadData()
    }, [contactsPage])

    return (
        <Stack
        sx={{
            width: '100%',
            bgcolor: 'neutral.50',
            boxShadow: '0 0 10px rgb(0,0,0,.125)',
            border: '1px solid',
            borderColor: 'neutral.600',
            borderRadius: '20px',
            p: '15px 10px',
            gap: '20px'
        }}
        >
        <Stack direction='row' sx={{justifyContent: 'space-between'}}>
        <Stack direction='row' sx={{alignItems: 'center', gap: '10px'}}>
        <Stack
        sx={{
            width: '40px', height: '40px',
            borderRadius: '50%',
            alignItems: 'center', justifyContent: 'center',
            bgcolor: '#C30B90',
        }}
        >
            <IoStatsChart size={25}  color={neutral[50]}/>
        </Stack>
        <Typography sx={{fontWeight: 500}}>
            {!loading ? `Contacts Sent To  (${totalContacts} Contacts)` : <Skeleton width={30} height={20}  />}
        </Typography>
        </Stack>

        </Stack>

        <TableContainer
        sx={{
            minHeight: '230px',
        }}
        >

        <Table
        sx={{
            minWidth: 650,
            '& th, td': {
                p: sm ? '1px 10px' : '3px 5px',
                fontWeight: 500,
                ...sm && {fontSize: '.9rem'}
            }
        }}
        >
            <TableHead sx={{bgcolor: 'transparent'}}>
            <TableDivider colSpan={4} />
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email Address</TableCell>
                <TableCell align="center">Clicks</TableCell>
            </TableRow>
            <TableDivider colSpan={3} />
            </TableHead>
            <TableBody>
            {   !loading && !tableData.length ? 
                <TableRow
                sx={{
                    bgcolor: '#F1F1F1'
                }}
                >
                    <TableCell colSpan={6} rowSpan={3} sx={{textAlign: 'center'}}>
                    {`Mail hasn't been sent to any contacts`}
                    </TableCell>
                </TableRow> :
                
                !loading ? (
                tableData.map(({ name, email, clicks }, k) => (
                    <TableRow
                        key={k}
                        sx={{
                            bgcolor: k % 2 ? 'neutral.50' : '#F1F1F1'
                        }}
                    >
                        <TableCell>
                        <Typography 
                        // sx={{fontSize: 'unset', fontWeight: 500}}
                        >
                        {name}
                        </Typography>
                        </TableCell>
                        <TableCell>
                        <Typography>
                        {email}
                        </Typography>
                        </TableCell>
                        <TableCell align="center">
                        <Typography>
                            {clicks}
                        </Typography>
                        </TableCell>
                    </TableRow>
                ))
            ) : (
                Array.from({length: 3}).map( (_, k) => (
                    <TableRow
                        key={k}
                        sx={{
                            bgcolor: k % 2 ? 'neutral.50' : '#F1F1F1'
                        }}
                    >
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                </TableRow>
                ) )
            )}

            </TableBody>
        </Table>        
        </TableContainer>

        {
            !loading && tableData.length !== 0 &&
            <Pagination
            count={Math.ceil(totalContacts/perPage)}
            size={ sm ? 'medium' : 'large'}
            page={contactsPage}
            sx={{
                alignSelf: 'flex-end',
                width: 'fit-content !important'
            }}
                onChange={(e, page) => setContactsPage(page)}
            />
        }
        </Stack>
    )
}

export default ClicksTable