import { CircularProgress, Divider, Fade, Popper, Stack, Typography } from "@mui/material"
import { AiFillCaretDown } from "react-icons/ai"
import { useState } from "react"
import { useRef } from "react"
import { useEffect } from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { fetchData } from "../../../utils/functions/fetchData"
import CustomButton from "../../Button"
import DeleteEmail from "./DeleteEmail"
import { useEmailsPageContext } from "../../../contexts/emailsPageContext"



const TableButton = ({emailData}) => {
    const [loading, setLoading] = useState(false)
    const [showPopOver, setShowPopOver] = useState(false)
    const navigate = useNavigate()
    const tableButtonRef = useRef(null)
    const popperRef = useRef(null)
    const {currentTab, setReloadEmails, setReloadArchivedEmails} = useEmailsPageContext()

    const [openDeleteModal, setOpenDeleteModal] = useState(false)


    const handleArchiveEmail = async () => {
        setLoading(true)
        try{
            const {message} = await fetchData({
                endpoint: '/markEmailAsAchive',
                payload: {id: emailData?.id}
            })
            toast.success(message)
            setReloadEmails((prevVal) => !prevVal)
            setReloadArchivedEmails((prevVal) => !prevVal)

        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }

    const handleUnArchiveEmail = async () => {
        setLoading(true)
        try{
            const {message} = await fetchData({
                endpoint: '/unmarkEmailAsAchive',
                payload: {id: emailData?.id}
            })
            toast.success(message)
            setReloadArchivedEmails((prevVal) => !prevVal)
            setReloadEmails((prevVal) => !prevVal)
        }
        catch(err){
            console.error(err.message)
            toast.error(err.message)
        }
        finally{
            setLoading(false)
        }
    }
    


    const popOverOptions = [
        {
            title: 'Delete',
            action: () => setOpenDeleteModal(true)
        }
    ]
    
    if (currentTab === 'overview') {
        popOverOptions.splice(1, 0,
            {
            title: 'Archive',
            action: handleArchiveEmail
            },
            {
            title: 'Clone',
            action: () => navigate(`/dashboard/compose/edit/mail/${emailData?.id}`)
            },
        )
    } 
    else if (currentTab === 'archived-emails') {
        popOverOptions.splice(1, 0, {
            title: 'UnArchive',
            action: handleUnArchiveEmail
        })
    }

    if(currentTab === 'overview' || currentTab === 'archived-emails'){
        popOverOptions.splice(1, 0,
            {
                title: 'Manage',
                action: () => {setShowPopOver(false); navigate(`/dashboard/emails/manage/${emailData?.id}`)}
            }
        )
    }

    if(currentTab === 'draft'){
        popOverOptions.splice(1, 0,
            {
                title: 'Edit',
                action: () => navigate(`/dashboard/compose/edit/draft/${emailData?.id}`)
            }
        )
    }
    


    const bodyHidePopup = (e) => {
        if (
            !tableButtonRef.current ||
            !popperRef.current ||
            (!tableButtonRef.current.contains(e.target) &&
            !popperRef.current.contains(e.target))
          ) {
            setShowPopOver(false)
          }
    }
    useEffect( () => {
        document.body.addEventListener('click', bodyHidePopup)
        return ( () => {
            document.body.removeEventListener('click', bodyHidePopup)
        } )
    }, [] )

    return (
        <Stack
        ref={tableButtonRef}
        direction='row'
        sx={{
            width: currentTab!=='draft' ? '140px' : 'fit-content',
            height: currentTab!=='draft' ? '40px' : 'fit-content',
            alignItems: 'center',
            bgcolor: 'primary.main',
            alignSelf: 'flex-start',
            color: 'neutral.50',
            borderRadius: '8px',
            cursor: !loading ? 'pointer' : 'not-allowed',
            ...loading && {bgcolor: 'neutral.500'},
            '& ::selection': {
                bgcolor: 'transparent'
            },
            position: 'relative'
        }}
        >
            {
            currentTab!=='draft' &&
            <>
            <Stack
            sx={{
                width: '70%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onClick={ () => !loading && navigate(`/dashboard/emails/viewdetails/${emailData?.id}`) }
            >
            <Typography sx={{
                color: 'neutral.50',
                alignItems: 'center',
                fontSize: '.8rem'
            }}>
                {!loading ? 'View Details' : <CircularProgress sx={{color: "neutral.50"}} size={25} />}
            </Typography>
            </Stack>
            <Divider
            sx={{
                borderWidth: '1px',
                borderColor: '#000',
                height: '95%',
            }}
            />
            </>
            }
            <Stack
            sx={{
                width: currentTab!=='draft' ? '30%' : 'fit-content',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                ...currentTab==='draft' && {
                    p: '10px 15px'
                }
            }}
            onClick={() => !loading && setShowPopOver(!showPopOver)}
            >
            <AiFillCaretDown size={15} />
            </Stack>



            {/* Pop Over */}
            <Popper
            ref={popperRef}
            open={!loading && showPopOver}
            anchorEl={tableButtonRef.current}
            transition
            sx={{
                zIndex: 1,
                width: '140px'
            }}
            onClick={(e) => e.stopPropagation()}
            >
            {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
            <Stack
            sx={{
                width: '100%',
                boxShadow: '0 0 10px rgb(0,0,0,.125)',
                borderRadius: '8px',
                bgcolor: 'background',
            }}
            >
            {
                popOverOptions.map( ({title, action}, k) => (
                    <CustomButton
                    key={k}
                    variant="normal"
                    sx={{
                        gap: '10px',
                        width: '100%',
                        p: '10px',
                        '&:hover':{
                            bgcolor: 'primary.main',
                            color: 'neutral.50'
                        },
                        '&:hover p':{
                            color: 'neutral.50'
                        }
                    }}
                    onClick={action}
                    >
                    <Typography sx={{color: 'neutral.900', fontSize: '.7rem', whiteSpace: 'nowrap', fontWeight: 500}}>
                        {title}
                    </Typography>
                    </CustomButton>
                ) )
            }
            </Stack>
            </Fade>
            )}
            </Popper>





        <DeleteEmail
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        emailData={emailData}
        />
        </Stack>
    )
}


export default TableButton